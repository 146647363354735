import React from 'react';
import "../Styles/Maintenance.scss";
import SalesBee from '../Images/metricinfo-payment-logo.svg';
import miniLogo from '../Images/metric-favicon.svg';
import { Link } from 'react-router-dom';

const Maintenance = () => {
    return (
        <div className="m-Page m-Page--ServiceUnavailable" id="top">
            {/* Page Container */}
            <div className="m-PageContainer">

                {/* Header */}
                <div className="m-HeaderContainer">
                    <header className="m-Header" role="banner">
                        <div className="m-Header-wrap u-Grid-container">
                            {/* Header Lockup */}
                            <div className="m-Header-lockup">
                                {/* <a className="m-Header-skipToContent" href="#content">Skip to content</a> */}
                                <span className="m-Header-logoLink">
                                    {/* <svg className="m-Header-logo" role="img" aria-labelledby="m-Header-logoText" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                                        <path d="M24.6 0C30.9 0 36 5.4 36 12s-5.1 12-11.4 12H11.4C5.1 24 0 18.6 0 12S5.1 0 11.4 0h13.2zm-.3 4H11.7c-4.1 0-7.4 3.6-7.4 8s3.3 8 7.4 8h12.6c4.1 0 7.4-3.6 7.4-8s-3.3-8-7.4-8z" fillRule="evenodd" clipRule="evenodd"></path>
                                    </svg> */}
                                    <img className="m-Header-logo" src={miniLogo} alt="" />
                                    <span className="m-Header-logoTagLine">
                                        <img src={SalesBee} alt="" />
                                    </span>
                                </span>
                            </div>
                            {/* // Header Lockup */}
                        </div>
                    </header>
                </div>
                {/* // Header */}

                {/* Body */}
                <main className="m-Body" id="content">
                    <div className="m-BodySections">
                        <div className="m-Section m-Section--ctaInline">
                            <div className="m-Section-content m-Section-content--main no-UI u-Grid-66-33">
                                <div className="u-Grid-container">
                                    <div className="u-Grid-row">
                                        <div className="u-Grid-col">
                                            {/* <img src="" alt=""> */}
                                        </div>
                                        <div className="u-Grid-col">
                                            <h2 className="m-Section-title">Box! Box! Box!</h2>
                                            <h3>Unscheduled Maintenance.</h3>
                                            <p>The pit crew are making adjustments and we will have you back on track very shortly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/* // Body */}

                {/* Footer */}
                <footer className="m-FooterContainer">
                    <div className="m-Footer">
                        <div className="u-Grid-container">
                            <div className="u-Grid-row">
                                <div className="u-Grid-col">
                                    <p className="m-Footer-copyright">&copy; MetricInfo</p>
                                </div>
                                <div className="u-Grid-col">
                                    <ul className="m-Footer-links">
                                        <li><Link to="https://www.metricinfo.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</Link></li>
                                        <li><Link to="https://www.metricinfo.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* // Footer */}

            </div>
            {/* // Page Container */}
        </div>
    );
}

export default Maintenance;
