import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useEffect, useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addBranchData, addUserData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { countriesList, indiaStatesList, karnatakaCities } from "../DropdownsData";

const AddBranch = (props) => {

    const filteredBranchData = props.filteredBranchData;
    const branchId = props.branchId;

    let dispatch = useDispatch();
    const [branchName, setBranchName] = useState("");
    const [country, setCountry] = useState({ value: "", label: "" });
    const [state, setState] = useState({ value: "", label: "" });
    const [city, setCity] = useState({ value: "", label: "" });
    const [postalCode, setPostalCode] = useState();
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNum, setPhoneNum] = useState();
    const [status, setStatus] = useState("Active");

    useEffect(() => {
        if (branchId && filteredBranchData && filteredBranchData.length > 0) {
            setBranchName(filteredBranchData[0].branch_name)
            setEmail(filteredBranchData[0].email)
            setPhoneNum(filteredBranchData[0].phone_number)
            setPostalCode(filteredBranchData[0].postal_code)
            setAddress(filteredBranchData[0].address)
        }
    }, [])

    useEffect(() => {
        if (branchId && filteredBranchData && filteredBranchData.length > 0) {
            countriesList.map((item) => {
                if (item.label === filteredBranchData[0].country)
                    setCountry(item)
            })
            indiaStatesList.map((item) => {
                if (item.label === filteredBranchData[0].state)
                    setState(item)
            })
            karnatakaCities.map((item) => {
                if (item.value === filteredBranchData[0].city)
                    setCity(item)
            })
        }
    }, [branchId, filteredBranchData])

    const handleBranchData = (e) => {
        e.preventDefault();
        // let data;
        const methodType = branchId ? "PUT" : "POST";
        const data = {
            "BRANCH_ID": branchId,
            "BRANCH_NAME": branchName,
            "ADDRESS": address,
            "CITY": city.label,
            "STATE": state.label,
            "COUNTRY": country.label,
            "POSTAL_CODE": postalCode,
            "PHONE_NUMBER": phoneNum,
            "EMAIL": email,
            "STATUS": status
        }


        let postData = {
            method: methodType,
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        dispatch(addBranchData(postData, closeModel, branchId,props.fetchBranchData))
    }

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div show={props.addCashModel} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Branch Details</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>Branch Details</h4>
                            <p>Please enter Branch infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                            onSubmit={handleBranchData}
                        >
                            <div className="row">

                                <div className="col-lg-12 col">
                                    <div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Branch Name"
                                                        className={`${branchName
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Branch Name"
                                                            value={branchName}
                                                            onChange={(e) => {
                                                                setBranchName(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select Country"
                                                        className={`${!country
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={countriesList}
                                                            placeholder="Select Country"
                                                            value={country.value && country}
                                                            onInputChange={countriesList.label}
                                                            onChange={(data) =>
                                                                setCountry(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (usercode == null || usercode == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                            style={{ marginTop: "10px" }}
                                        >
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select State"
                                                        className={`${!state
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={indiaStatesList}
                                                            placeholder="Select State"
                                                            value={state.value && state}
                                                            onInputChange={indiaStatesList.label}
                                                            onChange={(data) =>
                                                                setState(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq &&
                                                        (emailaddress == null || emailaddress == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select City"
                                                        className={`${!city
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={karnatakaCities}
                                                            placeholder="Select City"
                                                            value={city.value && city}
                                                            onInputChange={karnatakaCities.label}
                                                            onChange={(data) =>
                                                                setCity(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Email"
                                                        className={`${email
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="email"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Email"
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Contact Number"
                                                            className={`${phoneNum
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="tel"
                                                                autoComplete="off"
                                                                className="form-control add-user-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Contact Number"
                                                                value={phoneNum}
                                                                onChange={(e) => {
                                                                    setPhoneNum(e.target.value);
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Postal Code"
                                                        className={`${postalCode
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Postal Code"
                                                            value={postalCode}
                                                            onChange={(e) => {
                                                                setPostalCode(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="mb-3">
                                                        <FloatingLabel label='Address' className={`${address ? "float-input-visible" : "float-hidden float-input"}`}>
                                                            <textarea
                                                                rows="2"
                                                                name="issue"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Address"
                                                                value={address}
                                                                onChange={
                                                                    (e) => { setAddress(e.target.value) }
                                                                }
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBranch;