import React, { useState } from "react";
import "../Styles/Topbar.scss";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SBSaveUpdateBtn } from "./SbButtons/SbAddBtn";
import SbCancelBtn from "./SbButtons/SbCancelBtn";
// import profileimg from "../images/Admin1.png";

function Topbar(props) {

    const menuCollapse = props.menuCollapse;
    const toggleshift = props.toggleshift;
    const toggleMenu = props.toggleMenu;

    const [showLogOut, setShowlogOut] = useState(false);

    const logout = () => {
        sessionStorage.clear();
        // localStorage.clear();
        window.location.href = "/";
        setShowlogOut(false);
    };

    const logoutModal = () => {
        setShowlogOut(true);
    };
    const cancelModale = () => {
        setShowlogOut(false);
    };

    return (
        <div>
            {showLogOut && (
                <Modal
                    show={showLogOut}
                    onHide={cancelModale}
                    className="logout-modal-div"
                >
                    <div className="cat-col">
                        <div>
                            <div className="log-out-div">
                                <h6>Are you sure want to logout?</h6>
                            </div>
                            {/* <hr /> */}
                            <div className="sb-logout-btn">
                                <SBSaveUpdateBtn onClickEffect={logout} btnName="Yes" />
                                <SbCancelBtn btnName="No" onClickEffect={cancelModale} />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <div className="topbar">
                <nav className="navbar-custom" id="navbar-custom">
                    <div className="dropdown list-unstyled topbar-nav float-end">
                        <div className="dropdown header-item">
                            <button
                                type="button"
                                className="btn navdrp"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span className="d-flex align-items-center">
                                    <div style={{ width: "50px", marginRight: "10px" }}>
                                        <img
                                            className="sidbar logo-img rounded-circle header-profile-user"
                                            style={{ width: "40px" }}
                                            // src={profileimg}
                                            src="../images/Admin1.png"
                                            alt=""
                                        />
                                    </div>

                                    <span className="text-start ms-xl-2 d-flex align-items-center">
                                        <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16">
                                            <span>
                                                <span className="sb-user-name">
                                                    Systosoft Solutions
                                                </span>
                                                <br />
                                                <span className="sb-company-name fz15">
                                                    Systosoft
                                                </span>
                                            </span>
                                        </span>
                                        <span>
                                            &nbsp;&nbsp;
                                            <i
                                                className="fa fa-angle-down fs-12 ms-1"
                                                style={{ fontSize: "20px" }}
                                            ></i>
                                        </span>
                                    </span>
                                </span>
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-end custom-sb-drop"
                                data-popper-placement="bottom-end"
                            >
                                <div
                                    className="dropdown-item"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* <div
                                        image={`../images/Topbar/Settinglight.png`}
                                        link="/settings"
                                        tpName="Settings"
                                    /> */}
                                    <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => <Tooltip {...props}>Settings</Tooltip>}
                                        placement="top"
                                    >
                                        <div
                                            // onClick={logoutModal}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                                src={`../images/Topbar/Settinglight.png`}
                                                width="22px"
                                                alt="logout"
                                            />
                                        </div>
                                    </OverlayTrigger>
                                    <div className="icon-divider"></div>
                                    {/* <div
                                        image={`../images/Topbar/Profile-image.png`}
                                        link="/settings/Profile"
                                        tpName="Profile"
                                    /> */}
                                    <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => <Tooltip {...props}>Profile</Tooltip>}
                                        placement="top"
                                    >
                                        <div
                                            // onClick={logoutModal}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                                src={`../images/Topbar/Profile-image.png`}
                                                width="22px"
                                                alt="logout"
                                            />
                                        </div>
                                    </OverlayTrigger>
                                    <div className="icon-divider"></div>
                                    <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => <Tooltip {...props}>Log Out</Tooltip>}
                                        placement="top"
                                    >
                                        <div
                                            onClick={logoutModal}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                                src={`../images/Topbar/Logout.png`}
                                                width="22px"
                                                alt="logout"
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-unstyled topbar-nav">
                        <li>
                            <button
                                className="nav-link button-menu-mobile nav-icon"
                                id="togglemenu"
                                onClick={toggleMenu}
                            >
                                <div
                                    className={`closemenu ${toggleshift.style}`}
                                >
                                    {menuCollapse ? (
                                        <span className="fa fa-indent"></span>
                                    ) : (
                                        <span className="fa fa-dedent"></span>
                                    )}
                                </div>
                            </button>
                        </li>
                    </div>

                </nav>
            </div>
        </div>
    );
}

export default Topbar;
