import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import SbAddBtn from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
// import AddUserData from "./AddUserData";
import "../../Styles/AddPage.scss"
import "../../Styles/AddUserDetails.scss"
import OnlineTable from "./OnlineTable";
import AddOnline from "./AddOnline";

function Online() {

    const onlineTransaction = [
        {
            "transactionId": "#13746",
            "date": "15-07-2024",
            "amount": 10000.50,
            "status": "Completed",
            "paymentMethod": "Credit Card",
            "transactionDetails": "Payment for order #123"
        },
        {
            "transactionId": "#13747",
            "date": "15-07-2024",
            "amount": 7500.00,
            "status": "Pending",
            "paymentMethod": "PayPal",
            "transactionDetails": "Payment for order #124"
        }
    ]

    let tableHeader = [
        { name: "TRANSACTION ID", align: "left", width: "100px" },
        { name: "DATE", align: "left", width: "150px" },
        { name: "AMOUNT", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "150px" },
        { name: "PAYMENT METHOD", align: "left", width: "150px" },
        { name: "TRANSACTION DETAILS", align: "left", width: "150px" },
    ]

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addusermodelshow, setAddusermodelshow] = useState(false);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    const handleClose = () => {
        setAddusermodelshow(!addusermodelshow);
    };

    return (
        <div id="main-page">
            {!addusermodelshow && <>
                <div className="top-and-sidebar">
                    <SideNavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar toggleMenu={toggleMenu} menuCollapse={menuCollapse} toggleshift={toggleshift} />
                </div>

                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <div className="user-table">
                                <Row className="search-row">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToSettingPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">Online</h6>
                                        </div>
                                    </Col>
                                    <Col md={5} className="cat-col">
                                        <div className="input-div">
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                className="form-control setting-input"
                                                id="metricInfo_search_bar"
                                                value={searchValue}
                                            // onChange={(e) => setSearchData(e.target.value)}
                                            />
                                            {/* <i className="fa fa-search search-icon"></i> */}
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                {/* <div
                                                className="header-filter-btn"
                                            // onClick={() => setOpenFilterTab(!openFilterTab)}
                                            >
                                                <p className="card-head">
                                                    <i className="bi bi-sliders" />
                                                    &nbsp;&nbsp;Filters
                                                </p>
                                            </div> */}
                                                <SbAddBtn
                                                    onClickEffect={() => setAddusermodelshow(!addusermodelshow)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <OnlineTable
                                    tableHeader={tableHeader}
                                    onlineTransaction={onlineTransaction}
                                />
                            </div>
                        </div>
                    </div >
                </div >
            </>}
            {
                addusermodelshow && (
                    <AddOnline
                        // getUserDetails={getUserDetails}
                        addusermodelshow={addusermodelshow}
                        handleClose={handleClose}
                    />
                )
            }
        </div >
    );
}

export default Online;
