import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import SbAddBtn from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
// import AddUserData from "./AddUserData";
import "../../Styles/AddPage.scss"
import "../../Styles/AddUserDetails.scss"
import CustomerTable from "./CustomerTable";
import AddCustomer from "./AddCustomer";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomersData, getAllCustomers, loadAllCustomersData, loadCustomerCompanyDropdown, loadFilteredCustomerData } from "../../Redux/action";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";
import { indiaStatesList, karnatakaCities, optionsStatusList } from "../DropdownsData";
import * as XLSX from "xlsx";

function Customer() {
    let tableHeader = [
        { name: "COMPANY NAME", align: "left", width: "180px" },
        { name: "CONTACT PERSON", align: "left", width: "180px" },
        { name: "CONTACT NUMBER/EMAIL", align: "left", width: "150px" },
        { name: "BUSINESS TYPE", align: "left", width: "150px" },
        { name: "COUNTRY", align: "left", width: "150px" },
        { name: "STATE", align: "left", width: "150px" },
        { name: "CITY", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "150px" },
        { name: "ACTION", align: "left", width: "150px" },
    ]

    let dispatch = useDispatch();
    const { getCustomers } = useSelector((state) => state.data);
    const { getCustomerCompanyName } = useSelector((state) => state.data);
    const { getFilteredCustomerData } = useSelector((state) => state.data);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addusermodelshow, setAddusermodelshow] = useState(false);
    const [openFilterTab, setOpenFilterTab] = useState(false);
    const [filterCompanyName, setFilterCompanyName] = useState({ value: "", label: "" });
    const [filterStateName, setFilterStateName] = useState({ value: "", label: "" });
    const [filterCityName, setFilterCityName] = useState({ value: "", label: "" });
    const [filterStatus, setFilterStatus] = useState({ value: "", label: "" });
    const [filterArray, setFilterArray] = useState([]);
    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [filteredCustomers, setFilteredCustomers] = useState([]);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    useEffect(() => {
        // dispatch(loadAllCustomersData())
        dispatch(loadCustomerCompanyDropdown())
    }, [])

    const fetchCustomer = () => {
        if (filterArray.length > 0) {
            // dispatch(getAllCustomers([]))
            dispatch(loadFilteredCustomerData(filterArray, PageSize, offset, setIsLoading, setLoading, setHasMoreData, offset > 0));
        } else {
            dispatch(loadAllCustomersData(PageSize, offset, setIsLoading, setLoading, setHasMoreData));
        }
    };

    useEffect(() => {
        if (filterArray) {
            setOffset(0);
            setHasMoreData(true);
        }
    }, [filterArray]);

    useEffect(() => {
        fetchCustomer();
    }, [offset, filterArray]);

    const [filteredCustomerData, setFilteredCustomerData] = useState([])
    const [customId, setCustomId] = useState();
    const handleEdit = (id) => {
        const filteredData = getCustomers?.filter((data) => data.customerid === id);
        setFilteredCustomerData(filteredData);
        setCustomId(id);
        setAddusermodelshow(true);
    };

    const handleDropdownFilter = (data, type) => {
        let list = [];
        if (type === "companyName") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "COMPANYNAME");
            }
            list.push({ key: "COMPANYNAME", value: data.value });
            setFilterCompanyName(data);
        } else if (type === "stateName") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "STATE");
            }
            list.push({ key: "STATE", value: data.value });
            setFilterStateName(data);
        } else if (type === "cityName") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "CITY");
            }
            list.push({ key: "CITY", value: data.value });
            setFilterCityName(data);
        } else if (type === "status") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "STATUS");
            }
            list.push({ key: "STATUS", value: data.value });
            setFilterStatus(data);
            // setOffset(0); // Reset offset when filter changes
            // setHasMoreData(true);
            // dispatch(loadFilteredCustomerData(list, PageSize, 0, setIsLoading, setLoading, setHasMoreData, false));
        }
        setFilterArray(list);
    };

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        setFilteredCustomers(
            getCustomers?.filter((customer) =>
                Object.values(customer).some(
                    (value) =>
                        typeof value === "string" &&
                        value.toLowerCase().includes(searchValue.toLowerCase())
                )
            )
        );
    }, [getCustomers, searchValue]);


    const navigate = useNavigate();
    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    const handleClose = () => {
        setAddusermodelshow(!addusermodelshow);
    };

    const exportCustomerDetails = () => {
        const dataToExport = filterArray.length > 0
            ? getFilteredCustomerData
            : getCustomers;
        const ws = XLSX.utils.json_to_sheet(dataToExport.map((data) => ({
            "COMPANY NAME": data.companyname,
            "CONTACT PERSON": data.contactperson,
            "EMAIL": data.contactemail,
            "PHONE": data.contactphone,
            "BUSINESS TYPE": data.businesstype,
            "COUNTRY": data.country,
            "STATE": data.state,
            "CITY": data.city,
            "ADDRESS": data.address,
            "STATUS": data.status,
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "CustomerData");
        XLSX.writeFile(wb, "CustomerData.xlsx");
    };

    const clearFilters = () => {
        setFilterArray([]);
        setFilterCompanyName({ value: "", label: "" });
        setFilterStateName({ value: "", label: "" });
        setFilterCityName({ value: "", label: "" });
        setFilterStatus({ value: "", label: "" });
        // dispatch(getAllCustomers([]));
        dispatch(clearCustomersData());
    };

    return (
        <div id="main-page">
            {!addusermodelshow && <>
                <div className="top-and-sidebar">
                    <SideNavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar toggleMenu={toggleMenu} menuCollapse={menuCollapse} toggleshift={toggleshift} />
                </div>

                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <div className="user-table">
                                <Row className="search-row">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToSettingPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">Customers</h6>
                                        </div>
                                    </Col>
                                    <Col md={5} className="cat-col">
                                        <div className="input-div">
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                className="form-control setting-input"
                                                id="metricInfo_search_bar"
                                                value={searchValue}
                                                onChange={handleSearchInputChange}
                                            />
                                            {/* <i className="fa fa-search search-icon"></i> */}
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                <div
                                                    className="header-filter-btn"
                                                    onClick={() => setOpenFilterTab(!openFilterTab)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div>
                                                <SbAddBtn
                                                    onClickEffect={() => setAddusermodelshow(!addusermodelshow)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {openFilterTab && (
                                    <div className="page-filter-section">
                                        <div style={{ width: "100%" }}>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="clients-filter-input-boxs filter-box-div">
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={getCustomerCompanyName}
                                                                value={filterCompanyName.value && filterCompanyName}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "companyName")
                                                                }
                                                                placeholder="Select Company"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={indiaStatesList}
                                                                value={filterStateName.value && filterStateName}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "stateName")
                                                                }
                                                                placeholder="Select State"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={karnatakaCities}
                                                                value={filterCityName.value && filterCityName}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "cityName")
                                                                }
                                                                placeholder="Select City"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={optionsStatusList}
                                                                value={filterStatus.value && filterStatus}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "status")
                                                                }
                                                                placeholder="Select Status"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        <SbCancelBtn
                                                            btnName="Clear"
                                                            onClickEffect={clearFilters}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}
                                <CustomerTable
                                    tableHeader={tableHeader}
                                    handleEdit={handleEdit}
                                    getCustomers={
                                        searchValue
                                            ? filteredCustomers
                                            : filterArray.length > 0
                                                ? getFilteredCustomerData
                                                : getCustomers
                                    }
                                    fetchData={() => fetchCustomer(offset)}
                                    loading={loading}
                                    setPageSize={setPageSize}
                                    PageSize={PageSize}
                                    setLoading={setLoading}
                                    isLoading={isLoading}
                                    setOffset={setOffset}
                                    offset={offset}
                                    hasMoreData={hasMoreData}
                                    exportCustomerDetails={exportCustomerDetails}
                                />
                            </div>
                        </div>
                    </div >
                </div >
            </>}
            {
                addusermodelshow && (
                    <AddCustomer
                        addusermodelshow={addusermodelshow}
                        handleClose={handleClose}
                        filteredCustomerData={filteredCustomerData}
                        customId={customId}
                        fetchCustomer={fetchCustomer}
                    />
                )
            }
        </div >
    );
}

export default Customer;
