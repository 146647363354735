import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import SbAddBtn from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
// import AddUserData from "./AddUserData";
import "../../Styles/AddPage.scss"
import "../../Styles/AddUserDetails.scss"
import CashTable from "./CashTable";
import "../../Styles/Customer.scss"
import AddCash from "./AddCash";

function Cash() {

    const cashTransactions = [
        {
            date: "2024-07-12",
            transactionId: "TXN123456",
            description: "Payment from Client A",
            amount: 1000.0,
            type: "inflow",
            balance: 5000.0,
            category: "Sales",
            method: "Cash",
            status: "Completed",
        },
        {
            date: "2024-07-11",
            transactionId: "TXN123457",
            description: "Refund to Client B",
            amount: -200.0,
            type: "outflow",
            balance: 4000.0,
            category: "Refund",
            method: "Bank Transfer",
            status: "Completed",
        },
    ];

    let tableHeader = [
        { name: "DATE", align: "left", width: "150px" },
        { name: "TRANSACTION ID", align: "left", width: "200px" },
        { name: "DESCRIPTION", align: "left", width: "150px" },
        { name: "AMOUNT", align: "left", width: "150px" },
        { name: "TYPE", align: "left", width: "150px" },
        { name: "BALANCE", align: "left", width: "150px" },
        { name: "CATEGORY", align: "left", width: "150px" },
        { name: "METHOD", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "150px" },
    ]

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addCashModel, setAddCashmodel] = useState(false);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    const handleClose = () => {
        setAddCashmodel(!addCashModel);
    };

    return (
        <div id="main-page">
            {!addCashModel && <>
                <div className="top-and-sidebar">
                    <SideNavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar toggleMenu={toggleMenu} menuCollapse={menuCollapse} toggleshift={toggleshift} />
                </div>

                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <div className="user-table">
                                <Row className="search-row">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToSettingPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">Cash</h6>
                                        </div>
                                    </Col>
                                    <Col md={5} className="cat-col">
                                        <div className="input-div">
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                className="form-control setting-input"
                                                id="metricInfo_search_bar"
                                                value={searchValue}
                                            // onChange={(e) => setSearchData(e.target.value)}
                                            />
                                            {/* <i className="fa fa-search search-icon"></i> */}
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                {/* <div
                                                className="header-filter-btn"
                                            // onClick={() => setOpenFilterTab(!openFilterTab)}
                                            >
                                                <p className="card-head">
                                                    <i className="bi bi-sliders" />
                                                    &nbsp;&nbsp;Filters
                                                </p>
                                            </div> */}
                                                <SbAddBtn
                                                    onClickEffect={() => setAddCashmodel(!addCashModel)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <CashTable
                                    tableHeader={tableHeader}
                                    cashTransactions={cashTransactions}
                                />
                            </div>

                        </div>

                    </div >

                </div >

            </>}

            {
                addCashModel && (
                    <AddCash
                        // getUserDetails={getUserDetails}
                        addCashModel={addCashModel}
                        handleClose={handleClose}
                    />
                )
            }

        </div >
    );
}

export default Cash;
