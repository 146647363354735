import React, { useEffect, useState } from "react";
import "../Styles/Signin.scss";
import "../Styles/CommonStyle.scss";
import "../MediaFiles/Media.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Image, Carousel } from "react-bootstrap";
import travelize from "../Images/metric-favicon.svg";
import travelizeSalesBeeLogo from "../Images/metricinfo-payment-logo.svg";
import { useDispatch } from "react-redux";
import { addSignInData } from "../Redux/action";
// import { useAuth } from "./AuthContext";

const Signin = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    // const { login } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    const [rememberMe, setRememberMe] = useState(false);
    useEffect(() => {
        // Check if "Remember Me" is checked and email is stored in localStorage
        const rememberedCredentials = JSON.parse(localStorage.getItem("rememberedCredentials"));

        if (rememberedCredentials) {
            setEmail(rememberedCredentials.email);
            setPassword(rememberedCredentials.password);
            setRememberMe(true)
        } else {
            setEmail("");
            setPassword("");
            setRememberMe(false)
        }
    }, []);

    const toggleRememberMe = () => {
        setRememberMe(!rememberMe);
    };

    const navigateToDashboard = () => {
        window.location.href = "/";
    };

    const postSignInData = (e) => {
        e.preventDefault();
        const data = {
            "email": email,
            "password": password
        }
        let postData = {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        dispatch(addSignInData(postData, setSuccessMessage, setErrorMessage, rememberMe, email, password, navigateToDashboard))
    }

    return (
        <div>
            <>
                <div className="container-fluid">
                    <div className="sigin-page-body">
                        {/* <Row> */}
                        <div className="dflex-flow-row">
                            <Col
                                lg={5}
                                md={5}
                                sm={12}
                                className="signIn-container-left-div"
                            >
                                <div className="signIn-container-logo">
                                    <Image
                                        src={travelizeSalesBeeLogo}
                                        alt="MetricInfo"
                                        className="signIn-logo"
                                    />
                                </div>
                                <div className="signIn-container-gif">
                                    <Image
                                        src="../images/sign-animate.gif"
                                        alt="MetricInfo Motion Logo"
                                        className="signIn-moving-img"
                                    />
                                    <div className="text-sliding-below-img">
                                        <Carousel
                                            style={{ width: "100%" }}
                                            controls={false}
                                            indicators={false}
                                            interval={3000}
                                        >
                                            <Carousel.Item>
                                                <h6>
                                                    <b>Geolocation Monitoring</b>
                                                </h6>
                                                <p>
                                                    Real-time tracking to determine precise location
                                                </p>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6>
                                                    <b>Reporting and Analytics</b>
                                                </h6>
                                                <p>
                                                    Offering valuable insights for informed
                                                    decision-making
                                                </p>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <h6>
                                                    <b>Visits and Expenses</b>
                                                </h6>
                                                <p>
                                                    Ensuring optimal resource allocation
                                                    and cost control
                                                </p>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                                <div className="devider">
                                    <div className="v1"></div>
                                </div>
                            </Col>
                            <Col
                                lg={5}
                                md={5}
                                sm={12}
                                className="signIn-container-right-div"
                            >
                                <div className="metricinfo-logo">
                                    <Image
                                        src={travelize}
                                        alt="MetricInfo Logo"
                                        className="sb-trv-logo"
                                    />
                                </div>
                                <div className="signin-header login-part">
                                    <div className="signin-box">
                                        <div className="login-header-part">
                                            <h2 className="mb-1">Welcome back!</h2>
                                            <h4>Please sign in to continue</h4>
                                        </div>
                                        <form
                                            onSubmit={postSignInData}
                                            className="login-form"
                                            autocomplete="off"
                                        >
                                            <div className="form-group mb20">
                                                <div className="icon-with-input before">
                                                    <i className="fa fa-envelope" />
                                                    <input
                                                        type="email"
                                                        className="form-control signIn-form-control"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            setSuccessMessage("");
                                                            setErrorMessage("");
                                                        }}
                                                        required
                                                        maxLength={50}
                                                        name="email" // Add name attribute
                                                        autoComplete="username" // Add autocomplete attribute
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="icon-with-input before">
                                                    <i className="fa fa-lock"></i>
                                                    <input
                                                        //   type={passwordType}
                                                        className="form-control signIn-form-control"
                                                        placeholder="Enter your password"
                                                        value={password}
                                                        onChange={(e) => {
                                                            setPassword(e.target.value);
                                                            setSuccessMessage("");
                                                            setErrorMessage("");
                                                        }}
                                                        required
                                                        maxLength={20}
                                                        minLength={6}
                                                        name="password" // Add name attribute
                                                        autoComplete="current-password" // Add autocomplete attribute
                                                    />
                                                    <div className="pass-hide-show">
                                                        {passwordType === "password" ? (
                                                            <i
                                                                className="bi bi-eye-slash pass-hide-show"
                                                                onClick={togglePassword}
                                                            />
                                                        ) : (
                                                            <i
                                                                className="bi bi-eye pass-hide-show"
                                                                onClick={togglePassword}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="forgot-pass-n-get-otp dflex-j-between">
                                                <div className="signIn-footer ">
                                                    <div className="create-account-div d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={rememberMe}
                                                            onChange={toggleRememberMe}
                                                        />&nbsp;&nbsp;
                                                        <div
                                                            className="login-signup-text color-green"
                                                        >
                                                            Remember me
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="forget-pasword-div">
                                                    <Link
                                                        to="/updatedforgotPassword"
                                                        className="forgot-pass-text color-yellow"
                                                    >
                                                        Forgot Password?
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="signin-button mb20 hv-center">
                                                <Button
                                                    type="submit"
                                                    className="btn btn-signin-button"
                                                >
                                                    Sign In
                                                </Button>
                                            </div>
                                        </form>
                                        {successMessage && <div className="success-color pt-3 text-center"><b>{successMessage}</b></div>}
                                        {errorMessage && <div className="color-warning pt-3 text-center"><b>{errorMessage}</b></div>}
                                        <div className="signIn-footer mt20">
                                            <div className="create-account-div">
                                                New to MetricInfo?&nbsp;
                                                <Link
                                                    to="/signup"
                                                    className="login-signup-text color-green"
                                                >
                                                    Create an Account
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        {/* </Row> */}
                    </div>
                </div>
            </>
        </div>
    );
};

export default Signin;
