import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';

function BranchTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer
                    sx={{ maxHeight: 440 }}
                // onScroll={(e) =>
                //     handleScroll(
                //         e,
                //         props.fetchData,
                //         props.isLoading,
                //         props.setLoading,
                //         props.setOffset,
                //         props.offset,
                //         props.PageSize
                //     )
                // }
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="client-table"
                    >
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    <TableCell key={i} align={name.align} className={`thead-cell-products`} style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.getAllBrachData.length === 0 && !props.loading ? (
                                <TableRow>
                                    <TableCell align="center" colSpan={props.tableHeader.length}>
                                        --- NO DATA FOUND ---
                                    </TableCell>
                                </TableRow>
                            ) : (
                                props.getAllBrachData.map((data, i) => (
                                    <TableRow align="center" key={i} className="client-list-row-data">
                                        <TableCell>{data.branch_name ? data.branch_name : "NA"}</TableCell>
                                        <TableCell>{data.email ? data.email : "NA"}</TableCell>
                                        <TableCell>{data.phone_number ? data.phone_number : "NA"}</TableCell>
                                        <TableCell>{data.country ? data.country : "NA"}</TableCell>
                                        <TableCell>{data.state ? data.state : "NA"}</TableCell>
                                        <TableCell>{data.city ? data.city : "NA"}</TableCell>
                                        <TableCell>
                                            {data?.status === "Active" ? (
                                                <span className="status-badge complete-status">Active</span>
                                            ) : (
                                                <span className="status-badge cancel-status">InActive</span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <span className="user-action-td" onClick={() => props.handleEdit(data.branch_id)}>
                                                <Link className="user-edit-link">
                                                    <i className="fa fa-edit"></i> Edit
                                                </Link>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {props.loading && <OnScrollLoading />} */}
            </Paper>
        </div>
    );
}

export default BranchTable;


