import * as types from "./actionType";
const initialState = {

    // Subscription Page
    getSubscriptions: [],
    // Subscription Page
    // Users page
    getUsers: [],
    getUserCount: [],
    nextUrl: null,
    getUserDropdown: [],
    getUserDesignationDropdown: [],
    getUserReportingToDropdown: [],
    getFilteredUserData: [],
    totalCount: "",
    offsetCount: "",
    // Users page

    // Branch Page 
    getAllBrachData: [],
    getBranchDropdown: [],
    // Branch Page 

    // Customer Page
    getCustomers: [],
    getCustomerCompanyName: [],
    getFilteredCustomerData: [],
    // Customer Page
};

const designReducers = (state = initialState, action) => {
    switch (action.type) {
        // Subscription Page
        case types.post_Subscription_Data:
        case types.get_allSubscription_Data:
            return {
                ...state,
                getSubscriptions: action.payload,
                loading: false,
            }
        // Subscription Page

        // SignIn Page
        case types.post_SignIn_Data:
        // SignIn Page

        // Users Page
        case types.post_User_Data:
        case types.get_allUser_Data:
            if (action.payload) {
                return {
                    ...state,
                    getUsers: [...state.getUsers, ...action.payload],
                    loading: false,
                }
            }
        case types.get_User_Count:
            return {
                ...state,
                getUserCount: action.payload,
                loading: false,
            }
        case types.get_allUser_DropdownData:
            return {
                ...state,
                getUserDropdown: action.payload,
                loading: false,
            }
        case types.get_allUser_DesignationDropdownData:
            return {
                ...state,
                getUserDesignationDropdown: action.payload,
                loading: false,
            }
        case types.get_allUser_ReportingDropdownData:
            return {
                ...state,
                getUserReportingToDropdown: action.payload,
                loading: false,
            }
        case types.get_Filtered_UserData:
            if (action.payload) {
                if (action.payload) {
                    return {
                        ...state,
                        getFilteredUserData: action.append
                            ? [...state.getFilteredUserData, ...action.payload]
                            : action.payload,
                        loading: false,
                    }
                }
            }
        // Users Page

        // Customer Page
        case types.post_Customer_Data:
        case types.get_allCustomer_Data:
            if (action.payload) {
                return {
                    ...state,
                    // getCustomers: action.payload,
                    getCustomers: [...state.getCustomers, ...action.payload],
                    loading: false,
                }
            }
        case types.get_Customer_CompanyName:
            return {
                ...state,
                getCustomerCompanyName: action.payload,
                loading: false,
            }
        case types.get_Filtered_CustomerData:
            if (action.payload) {
                return {
                    ...state,
                    getFilteredCustomerData: action.append
                        ? [...state.getFilteredCustomerData, ...action.payload]
                        : action.payload,
                    // getFilteredCustomerData: action.payload,
                    loading: false,
                }
            }
        case types.clear_Customers_Data:
            return {
                ...state,
                getCustomers: [],
                getFilteredCustomerData: [],
                loading: false,
            };
        // Customer Page

        // Lazy Loading
        case types.Get_Total_Data:
            return {
                ...state,
                totalCount: action.payload,
                loading: false,
            };
        case types.SET_NEXT_URL:
            return {
                ...state,
                nextUrl: action.payload
            };
        case types.get_Offset_Count:
            return {
                ...state,
                offsetCount: action.payload
            };
        // Lazy Loading

        // Branch Page
        case types.post_BranchData:
        case types.get_All_BranchData:
            return {
                ...state,
                getAllBrachData: action.payload,
                loading: false,
            };
        case types.get_branchDropdown:
            return {
                ...state,
                getBranchDropdown: action.payload,
                loading: false,
            };
        // Branch Page
        default:
            return state;
    }
};

export default designReducers;
