import React from "react";

function OnScrollLoading(props) {
    return (
        <div id="preloader">
            <div id="loading-wrapper" className="show">
                <div id="loading-text">
                    <img
                        src="../../../../images/DefaultImages/loading-logo.svg"
                        alt="loading"
                    />
                </div>
                <div id="loading-content"></div>
            </div>
        </div>
    );
}

export default OnScrollLoading;

export const handleScroll = (
    event,
    fetchData,
    isLoading,
    setLoading,
    setOffset,
    offset,
    PageSize,
    hasMoreData
) => {

    // const { scrollTop, clientHeight, scrollHeight } = event.target;
    // if (scrollHeight - scrollTop <= clientHeight + 100) {
    //     setLoading(true);
    //     setOffset(prevOffset => prevOffset + PageSize);
    // }

    if (hasMoreData && !isLoading) {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        if (scrollHeight - scrollTop <= clientHeight + 100) {
            setLoading(true);
            setOffset(prevOffset => prevOffset + PageSize);
        }
    }
};

