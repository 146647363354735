import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useEffect, useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addUserData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";

const branchList = [
    { value: 1, label: "Karnataka" },
    { value: 2, label: "Andrapradesh" },
    { value: 3, label: "Tamilnadu" },
]

const designationList = [
    { value: "Collection", label: "Collection" },
]

const reportingToList = [
    { value: "Admin", label: "Admin" },
]

const AddUserData = (props) => {

    const filteredUserData = props.filteredUserData;
    const userId = props.userId;

    let dispatch = useDispatch();
    const [localImage, setLocalImage] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [branchId, setBranchId] = useState({ value: "", label: "" });
    const [contactNum, setContactNum] = useState("")
    const [designation, setDesignation] = useState({ value: "", label: "" })
    const [reportingTo, setReportingTo] = useState({ value: "", label: "" })
    const [status, setStatus] = useState("Active");

    useEffect(() => {
        if (userId && filteredUserData && filteredUserData.length > 0) {
            setFullName(filteredUserData[0].name)
            setEmail(filteredUserData[0].email)
            setContactNum(filteredUserData[0].phonenumber)
        }
    }, [])

    useEffect(() => {
        if (userId && filteredUserData && filteredUserData.length > 0) {
            designationList.map((item) => {
                if (item.value === filteredUserData[0].designation)
                    setDesignation(item)
            })
            branchList.map((item) => {
                if (item.value === filteredUserData[0].branchid)
                    setBranchId(item)
            })
            reportingToList.map((item) => {
                if (item.value === filteredUserData[0].reportingto)
                    setReportingTo(item)
            })
        }
    }, [userId, filteredUserData])

    const handleUserData = (e) => {
        e.preventDefault();
        // Get the current date and time in the desired 24-hour format
        const formattedDateTime = moment().format('DD-MM-YYYY HH:mm');

        // Convert the formatted date and time to an integer by removing special characters
        const dateTimeInt = parseInt(formattedDateTime.replace(/[-\s:]/g, ''));
        let data;
        let methodType = "POST";
        if (userId) {
            data = {
                "ID": userId,
                "NAME": fullName,
                "EMAIL": email,
                "PHONENUMBER": contactNum,
                "BRANCHID": branchId.value,
                "DESIGNATION": designation.value,
                "REPORTINGTO": reportingTo.value,
                "STATUS": status
            }
            methodType = "PUT";
        } else {
            data = {
                "id": parseInt(dateTimeInt),
                "name": fullName,
                "email": email,
                "phone_number": contactNum,
                "branch_id": branchId.value,
                "designation": designation.value,
                "reporting_to": reportingTo.value,
                "status": status
            }
        }
        let postData = {
            method: methodType,
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        dispatch(addUserData(postData, closeModel, userId))
    }

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div show={props.addusermodelshow} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> User Details</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>User Details</h4>
                            <p>Please enter user infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                            onSubmit={handleUserData}
                        >
                            <div className="row">
                                <div className="col-lg-3 col">
                                    <div className="add-user-main-logo-image">
                                        <img
                                            className="add-user-images"
                                            src={localImage ? localImage : profileimg}
                                            alt="Profile Images"
                                        />
                                        <div>
                                            <button
                                                type="button"
                                                className="btn upload-logo"
                                            // onClick={uploadClick}
                                            >
                                                {/* <img
                                                    src="../images/uploadImage/camera.svg"
                                                    alt="camera"
                                                /> */}
                                                <i className="bi bi-camera-fill" />
                                            </button>
                                            <input
                                                id="getFile"
                                                type="file"
                                                name="filetobase64"
                                                accept="image/*"
                                                className="btn-primary upload"
                                                // ref={hiddenFileInput}
                                                // onChange={handleChange}
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col">
                                    <div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Full Name *"
                                                        className={`${fullName
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Full Name *"
                                                            value={fullName}
                                                            onChange={(e) => {
                                                                setFullName(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="User Id *"
                                                        className={`${userId
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="User Id *"
                                                            value={userId}
                                                            onChange={(e) => {
                                                                setUserId(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Branch *"
                                                        className={`${!branchId
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            // defaultValue={designationList[null]}
                                                            options={branchList}
                                                            placeholder="Branch *"
                                                            value={branchId.value && branchId}
                                                            onInputChange={branchList.label}
                                                            onChange={(data) =>
                                                                setBranchId(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (usercode == null || usercode == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                            style={{ marginTop: "10px" }}
                                        >
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="E-Mail Address *"
                                                        className={`${email
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="email"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="E-Mail Address *"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq &&
                                                        (emailaddress == null || emailaddress == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="input-mobile-field d-flex">
                                                        <div
                                                            className="mobile-num"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <FloatingLabel
                                                                label="Mobile *"
                                                                className={`${contactNum
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="tel"
                                                                    autoComplete="off"
                                                                    className="form-control add-user-input"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Mobile *"
                                                                    maxLength={10}
                                                                    value={contactNum}
                                                                    onChange={(e) => setContactNum(e.target.value)}
                                                                // onKeyDown={NumberValidation}
                                                                />
                                                            </FloatingLabel>
                                                            {/* {fieldReq && mobilenum.length !== 10 ? (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {`${alertMessage.message} - 10 Numbers`}&nbsp;
                                                                    <i className="fa fa-exclamation-circle" />
                                                                </small>
                                                            ) : (
                                                                ""
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Designation *"
                                                        className={`${!designation
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={designationList}
                                                            placeholder="Designation *"
                                                            value={designation.value && designation}
                                                            onInputChange={designationList.label}
                                                            onChange={(data) =>
                                                                setDesignation(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {(fieldReq && reportDesignation === "") ||
                                                        (fieldReq && reportDesignation === 0) ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select Reporting *"
                                                        className={`${!reportingTo
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={reportingToList}
                                                            placeholder="Select Reporting *"
                                                            value={reportingTo.value && reportingTo}
                                                            onInputChange={reportingToList.label}
                                                            onChange={(data) => setReportingTo(data)}
                                                            isSearchable={true}
                                                            autoComplete={false}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {(fieldReq && manager === "") ||
                                                        (fieldReq && manager === 0) ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUserData;