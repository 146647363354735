import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addUserData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const paymentMethods = [
    { value: "Credit Card", label: "Credit Card" },
    { value: "Debit Card", label: "Debit Card" },
    { value: "Bank Transfer", label: "Bank Transfer" },
]

const AddCash = (props) => {

    let dispatch = useDispatch();
    const [amount, setAmount] = useState("");
    const [paymentMethod, setpaymentMethod] = useState({ value: "", label: "" });
    const [transactionId, setTransactionId] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [description, setDescription] = useState("");

    const handleSelectChange = (date) => {
        setSelectedDate(date);
    };

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div show={props.addCashModel} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Cash Transaction</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>Cash Transaction Details</h4>
                            <p>Please enter cash transaction infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                        // onSubmit={handleUserData}
                        >
                            <div className="row">

                                <div className="col-lg-12 col">
                                    <div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Amount"
                                                        className={`${amount
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Amount"
                                                            value={amount}
                                                        // onChange={(e) => {
                                                        //     setFullName(e.target.value);
                                                        // }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Payment Method"
                                                        className={`${!paymentMethod
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={paymentMethods}
                                                            placeholder="Payment Method"
                                                            value={paymentMethod.value && paymentMethod}
                                                            onInputChange={paymentMethods.label}
                                                            // onChange={(data) =>
                                                            //     setBranchId(data)
                                                            // }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (usercode == null || usercode == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                            style={{ marginTop: "10px" }}
                                        >
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Transaction Id"
                                                        className={`${transactionId
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Transaction Id"
                                                            value={transactionId}
                                                            // onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq &&
                                                        (emailaddress == null || emailaddress == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel label='Date' className={`${selectedDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            className={`form-control add-user-input`}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholder="Date"
                                                            onChange={(date) => handleSelectChange(date)}
                                                            maxDate={new Date()}
                                                        // readOnly={compaintId}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="mb-3">
                                                        <FloatingLabel label='Description' className={`${description ? "float-input-visible" : "float-hidden float-input"}`}>
                                                            <textarea
                                                                rows="3"
                                                                name="issue"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Description"
                                                                value={description}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCash;