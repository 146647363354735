import React, { useState } from 'react';
import { ProSidebar, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import miniLogo from '../Images/metric-favicon.svg';
import SalesBee from '../Images/metricinfo-payment-logo.svg';
import "../Styles/Sidenavbar.scss"

const SideNavbar = (props) => {
    const menuCollapse = props.menuCollapse;
    const [subId, setSubId] = useState("");
    const [open, setOpen] = useState(false);

    const subMenuOpen = (e) => {
        setSubId(e.target.id);
        setOpen(!open);
    };

    return (
        <div id="header">
            <ProSidebar
                collapsed={menuCollapse}
                className={`pro-side-bar ${menuCollapse ? 'collapsed-bar' : 'expanded-bar'}`}
            >
                <SidebarHeader className="side_bar_header_main">
                    <div className="logotext">
                        <div className="logo profile-mini">
                            {menuCollapse ? (
                                <div className="profile-image">
                                    <img src={miniLogo} alt="Profile-Img" />
                                </div>
                            ) : (
                                <Link className="travelize_logo" to="/dashboard">
                                    <img src={SalesBee} alt="MetricInfo-Logo" className="salesbee-logo" />
                                </Link>
                            )}
                        </div>
                    </div>
                </SidebarHeader>

                <div className="profile-image-sidebar-big">
                    <div className="profile-controls"></div>
                </div>

                <SidebarContent className="sidebar_content sidenavbar-scroll" id="sidenavAccordion">
                    {!menuCollapse ? (
                        <div className="expanded-nav-bar">
                            <Link to="/dashboard">
                                <div
                                    className={
                                        window.location.hash === `#/dashboard` || window.location.hash === ''
                                            ? 'sidebar-item plain active'
                                            : 'sidebar-item plain'
                                    }
                                >
                                    <li>
                                        <div className="nav-link-item d-flex">
                                            <div className="fa-icon-div">
                                                <img src="../images/SideBar/Dashboard.svg" alt="" width="100%" />
                                            </div>
                                            <span className="xn-text">Dashboard</span>
                                        </div>
                                    </li>
                                </div>
                            </Link>
                            <div
                                className={
                                    (subId === "collection" && open) ||
                                        window.location.hash === `#/cash` ||
                                        window.location.hash === `#/check` ||
                                        window.location.hash === `#/online`
                                        ? "sidebar-item open"
                                        : "sidebar-item"
                                }
                            >
                                <li
                                    name="COLLECTION"
                                    id="collection"
                                    onClick={subMenuOpen}
                                    className={
                                        (subId === "collection" && open) ||
                                            window.location.hash === `#/cash` ||
                                            window.location.hash === `#/check` ||
                                            window.location.hash === `#/online`
                                            ? "nav-link-item drop-down-item active"
                                            : "nav-link-item drop-down-item drop-main"
                                    }
                                >
                                    <div className="sidebar-title" id="collection">
                                        <div className="fa-icon-div">
                                            <img src="../images/SideBar/Collection.svg" alt="" width="100%" />
                                        </div>
                                        <span className="xn-text" name="COLLECTION" id="collection">
                                            Collection
                                        </span>
                                        <i className="bi-chevron-right toggle-btn" id="collection" />
                                    </div>
                                </li>
                                {(subId === "collection" && open) ||
                                    window.location.hash === `#/cash` ||
                                    window.location.hash === `#/check` ||
                                    window.location.hash === `#/online` ? (
                                    <>
                                        <div>
                                            <Link to="/cash">
                                                <div
                                                    className={
                                                        window.location.hash === `#/cash`
                                                            ? `sidebar-content sub-active`
                                                            : `sidebar-content`
                                                    }
                                                >
                                                    <div className="nav-link-item">
                                                        <span className="bi bi-check-square-fill"></span>
                                                        <span className="xn-text">Cash</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/check">
                                                <div
                                                    className={
                                                        window.location.hash === `#/check`
                                                            ? `sidebar-content sub-active`
                                                            : `sidebar-content`
                                                    }
                                                >
                                                    <div className="nav-link-item">
                                                        <span className="bi bi-check-square-fill"></span>
                                                        <span className="xn-text">Check</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/online">
                                                <div
                                                    className={
                                                        window.location.hash === `#/online`
                                                            ? `sidebar-content sub-active`
                                                            : `sidebar-content`
                                                    }
                                                >
                                                    <div className="nav-link-item">
                                                        <span className="bi bi-check-square-fill"></span>
                                                        <span className="xn-text">Online</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div
                                className={
                                    (subId === "masters" && open) ||
                                        window.location.hash === `#/branch` ||
                                        window.location.hash === `#/users` ||
                                        window.location.hash === `#/customers`
                                        ? "sidebar-item open"
                                        : "sidebar-item"
                                }
                            >
                                <li
                                    name="MASTERS"
                                    id="masters"
                                    onClick={subMenuOpen}
                                    className={
                                        (subId === "masters" && open) ||
                                            window.location.hash === `#/branch` ||
                                            window.location.hash === `#/users` ||
                                            window.location.hash === `#/customers`
                                            ? "nav-link-item drop-down-item active"
                                            : "nav-link-item drop-down-item drop-main"
                                    }
                                >
                                    <div className="sidebar-title" id="masters">
                                        <div className="fa-icon-div">
                                            <img src="../images/SideBar/Masters.svg" alt="" width="100%" />
                                        </div>
                                        <span className="xn-text" name="MASTERS" id="masters">
                                            Masters
                                        </span>
                                        <i className="bi-chevron-right toggle-btn" id="masters" />
                                    </div>
                                </li>
                                {(subId === "masters" && open) ||
                                    window.location.hash === `#/branch` ||
                                    window.location.hash === `#/users` ||
                                    window.location.hash === `#/customers` ? (
                                    <>
                                        <div>
                                            <Link to="/branch">
                                                <div
                                                    className={
                                                        window.location.hash === `#/branch`
                                                            ? `sidebar-content sub-active`
                                                            : `sidebar-content`
                                                    }
                                                >
                                                    <div className="nav-link-item">
                                                        <span className="bi bi-check-square-fill"></span>
                                                        <span className="xn-text">Branch</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/users">
                                                <div
                                                    className={
                                                        window.location.hash === `#/users`
                                                            ? `sidebar-content sub-active`
                                                            : `sidebar-content`
                                                    }
                                                >
                                                    <div className="nav-link-item">
                                                        <span className="bi bi-check-square-fill"></span>
                                                        <span className="xn-text">Users</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/customers">
                                                <div
                                                    className={
                                                        window.location.hash === `#/customers`
                                                            ? `sidebar-content sub-active`
                                                            : `sidebar-content`
                                                    }
                                                >
                                                    <div className="nav-link-item">
                                                        <span className="bi bi-check-square-fill"></span>
                                                        <span className="xn-text">Customer</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                            <Link to="/deposite">
                                <div
                                    className={
                                        window.location.hash === `#/deposite`
                                            ? 'sidebar-item plain active'
                                            : 'sidebar-item plain'
                                    }
                                >
                                    <li>
                                        <div className="nav-link-item d-flex">
                                            <div className="fa-icon-div">
                                                <img src="../images/SideBar/Deposite.svg" alt="" width="100%" />
                                            </div>
                                            <span className="xn-text">Deposite</span>
                                        </div>
                                    </li>
                                </div>
                            </Link>
                            <Link to="/refund">
                                <div
                                    className={
                                        window.location.hash === `#/refund`
                                            ? 'sidebar-item plain active'
                                            : 'sidebar-item plain'
                                    }
                                >
                                    <li>
                                        <div className="nav-link-item d-flex">
                                            <div className="fa-icon-div">
                                                <img src="../images/SideBar/Refund.svg" alt="" width="100%" />
                                            </div>
                                            <span className="xn-text">Refund</span>
                                        </div>
                                    </li>
                                </div>
                            </Link>
                            <Link to="/recurring">
                                <div
                                    className={
                                        window.location.hash === `#/recurring`
                                            ? 'sidebar-item plain active'
                                            : 'sidebar-item plain'
                                    }
                                >
                                    <li>
                                        <div className="nav-link-item d-flex">
                                            <div className="fa-icon-div">
                                                <img src="../images/SideBar/Recurring.svg" alt="" width="100%" />
                                            </div>
                                            <span className="xn-text">Recurring</span>
                                        </div>
                                    </li>
                                </div>
                            </Link>
                            <Link to="/reports">
                                <div
                                    className={
                                        window.location.hash === `#/reports`
                                            ? 'sidebar-item plain active'
                                            : 'sidebar-item plain'
                                    }
                                >
                                    <li>
                                        <div className="nav-link-item d-flex">
                                            <div className="fa-icon-div">
                                                <img src="../images/SideBar/Reports.svg" alt="" width="100%" />
                                            </div>
                                            <span className="xn-text">Reports</span>
                                        </div>
                                    </li>
                                </div>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-dashboard`}>
                                            Dashboard
                                        </Tooltip>
                                    }
                                >
                                    <li id="#dashboard">
                                        <NavLink to="/dashboard">
                                            <img
                                                src="../images/SideBar/Dashboard.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-collection`}>
                                            Collection
                                        </Tooltip>
                                    }
                                >
                                    <li id="#collection">
                                        <NavLink to="/collection">
                                            <img
                                                src="../images/SideBar/Collection.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-masters`}>
                                            Masters
                                        </Tooltip>
                                    }
                                >
                                    <li id="#masters">
                                        <NavLink to="/masters">
                                            <img
                                                src="../images/SideBar/Masters.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-deposite`}>
                                            Deposite
                                        </Tooltip>
                                    }
                                >
                                    <li id="#deposite">
                                        <NavLink to="/deposite">
                                            <img
                                                src="../images/SideBar/Deposite.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-refund`}>
                                            Refund
                                        </Tooltip>
                                    }
                                >
                                    <li id="#refund">
                                        <NavLink to="/refund">
                                            <img
                                                src="../images/SideBar/Refund.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-recurring`}>
                                            Recurring
                                        </Tooltip>
                                    }
                                >
                                    <li id="#recurring">
                                        <NavLink to="/recurring">
                                            <img
                                                src="../images/SideBar/Recurring.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                            <div className="collapsed-nav-bar">
                                <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 250, hide: 300 }}
                                    overlay={
                                        <Tooltip id={`tooltip-reports`}>
                                            Reports
                                        </Tooltip>
                                    }
                                >
                                    <li id="#reports">
                                        <NavLink to="/reports">
                                            <img
                                                src="../images/SideBar/Reports.svg"
                                                alt=""
                                                width="20px"
                                            />
                                        </NavLink>
                                    </li>
                                </OverlayTrigger>
                            </div>
                        </div>
                    )
                    }
                </SidebarContent >
            </ProSidebar >
        </div >
    );
};

export default SideNavbar;

