import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useEffect, useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addSubscriptionData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddSubscription = (props) => {

    const compId = props.compId;
    const filteredData = props.filteredData;

    let dispatch = useDispatch();
    const [localImage, setLocalImage] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [customerId, setCustomerId] = useState();
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState();
    const [contactNum, setContactNum] = useState("")
    const [selectedSubDate, setSelectedSubDate] = useState()
    const [subType, setSubType] = useState("")
    const [subStartDate, setSubStartDate] = useState();
    const [subEndDate, setSubEndDate] = useState();
    const [noOfUsers, setNoOfUsers] = useState();
    const [bde, setBde] = useState("");
    const [leadGen, setLeadGen] = useState("");
    const [firstSubDate, setFirstSubDate] = useState();
    const [status, setStatus] = useState("Active");

    useEffect(() => {
        if (compId && filteredData && filteredData.length > 0) {
            setCompanyName(filteredData[0].companyname)
            setContactPerson(filteredData[0].contactperson)
            setEmail(filteredData[0].emailid)
            setContactNum(filteredData[0].phonenumber)
            setSelectedSubDate(filteredData[0].subscriptiondate)
            setSubType(filteredData[0].subtype)
            setSubStartDate(filteredData[0].startdate)
            setSubEndDate(filteredData[0].enddate)
            setNoOfUsers(filteredData[0].numberofusers)
            setBde(filteredData[0].bde)
            setLeadGen(filteredData[0].leadgen)
            setFirstSubDate(filteredData[0].firstsubdate)
        }
    }, [compId, filteredData])

    const handleSelectChange = (date, type) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;
        if (type === "subDate") {
            setSelectedSubDate(formattedDate);
        } else if (type === "subStart") {
            setSubStartDate(formattedDate);
        } else if (type === "subEnd") {
            setSubEndDate(formattedDate);
        } else if (type === "firstSubDate") {
            setFirstSubDate(formattedDate);
        }
    };

    const uniqueID =
        Math.floor(Math.random() * 10000) + Math.floor(Math.random() * 5000);

    const handleCustomerData = (e) => {
        e.preventDefault();
        let data;
        if (compId) {
            data = {
                "COMPANYID": compId,
                "COMPANYNAME": companyname,
                "CONTACTPERSON": contactPerson,
                "EMAILID": email,
                "PHONENUMBER": contactNum,
                // "SUBSCRIPTIONDATE": selectedSubDate,
                "SUBSCRIPTIONDATE": selectedSubDate ? moment(selectedSubDate).format('YYYY-MM-DD') : null,
                "SUBTYPE": subType,
                // "STARTDATE": subStartDate,
                "STARTDATE": subStartDate ? moment(subStartDate).format('YYYY-MM-DD') : null,
                // "ENDDATE": subEndDate,
                "ENDDATE": subEndDate ? moment(subEndDate).format('YYYY-MM-DD') : null,
                "NUMBEROFUSERS": parseInt(noOfUsers),
                "BDE": bde,
                "LEADGEN": leadGen,
                "STATUS": "Active",
                // "FIRSTSUBDATE": firstSubDate
                "FIRSTSUBDATE": firstSubDate ? moment(firstSubDate).format('YYYY-MM-DD') : null
            };
        } else {
            data = {
                "companyid": uniqueID,
                "companyname": companyname,
                "contactperson": contactPerson,
                "emailid": email,
                "phonenumber": contactNum,
                "subscriptiondate": selectedSubDate,
                "subtype": subType,
                "startdate": subStartDate,
                "enddate": subEndDate,
                "numberofusers": parseInt(noOfUsers),
                "bde": bde,
                "leadgen": leadGen,
                "status": "Active",
                "firstsubdate": firstSubDate
            };
        }

        let postData = {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        console.log(data, 'data')
        dispatch(addSubscriptionData(postData, closeModel, compId))
    }

    const closeModel = () => {
        props.setFilteredData([]);
        // props.setComId();
        setCompanyName("");
        setContactPerson("");
        setEmail("");
        setContactNum("");
        setSelectedSubDate();
        setSubType("");
        setSubStartDate();
        setSubEndDate();
        setNoOfUsers();
        setBde("");
        setLeadGen("");
        setFirstSubDate();
        props.handleClose();
    };

    return (
        <div show={props.addusermodelshow} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Subscription Details</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>Subscription Details</h4>
                            <p>Please enter subscription infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                            onSubmit={handleCustomerData}
                        >
                            <div className="row">
                                <div className="col-lg-3 col">
                                    <div className="add-user-main-logo-image">
                                        <img
                                            className="add-user-images"
                                            src={localImage ? localImage : profileimg}
                                            alt="Profile Images"
                                        />
                                        <div>
                                            <button
                                                type="button"
                                                className="btn upload-logo"
                                            // onClick={uploadClick}
                                            >
                                                {/* <img
                                                    src="../images/uploadImage/camera.svg"
                                                    alt="camera"
                                                /> */}
                                                <i className="bi bi-camera-fill" />
                                            </button>
                                            <input
                                                id="getFile"
                                                type="file"
                                                name="filetobase64"
                                                accept="image/*"
                                                className="btn-primary upload"
                                                // ref={hiddenFileInput}
                                                // onChange={handleChange}
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col">
                                    <div>
                                        <div className="row add-user-image-container-div">

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Company Name *"
                                                        className={`${companyname
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Company Name *"
                                                            value={companyname}
                                                            onChange={(e) => {
                                                                setCompanyName(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Contact Person *"
                                                        className={`${contactPerson
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Contact Person *"
                                                            value={contactPerson}
                                                            onChange={(e) => setContactPerson(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq &&
                                                        (emailaddress == null || emailaddress == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                        >

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="input-mobile-field d-flex">
                                                        <div
                                                            className="mobile-num"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <FloatingLabel
                                                                label="Email Id *"
                                                                className={`${email
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="email"
                                                                    autoComplete="off"
                                                                    className="form-control add-user-input"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Email Id *"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                // onKeyDown={NumberValidation}
                                                                />
                                                            </FloatingLabel>
                                                            {/* {fieldReq && mobilenum.length !== 10 ? (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {`${alertMessage.message} - 10 Numbers`}&nbsp;
                                                                    <i className="fa fa-exclamation-circle" />
                                                                </small>
                                                            ) : (
                                                                ""
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="input-mobile-field d-flex">
                                                        <div
                                                            className="mobile-num"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <FloatingLabel
                                                                label="Contact Number *"
                                                                className={`${contactNum
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="tel"
                                                                    autoComplete="off"
                                                                    className="form-control add-user-input"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Contact Number *"
                                                                    maxLength={10}
                                                                    value={contactNum}
                                                                    onChange={(e) => setContactNum(e.target.value)}
                                                                // onKeyDown={NumberValidation}
                                                                />
                                                            </FloatingLabel>
                                                            {/* {fieldReq && mobilenum.length !== 10 ? (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {`${alertMessage.message} - 10 Numbers`}&nbsp;
                                                                    <i className="fa fa-exclamation-circle" />
                                                                </small>
                                                            ) : (
                                                                ""
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel label='Subscription Date' className={`${selectedSubDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={selectedSubDate ? moment(selectedSubDate, 'YYYY-MM-DD').toDate() : null}
                                                            className={`form-control add-user-input`}
                                                            // dateFormat="dd/MM/yyyy"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText="Subscription Date"
                                                            onChange={(date) => handleSelectChange(date, "subDate")}
                                                        // maxDate={new Date()}
                                                        // readOnly={compaintId}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Subscription Type *"
                                                        className={`${subType
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Subscription Type *"
                                                            value={subType}
                                                            onChange={(e) => setSubType(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row add-user-image-container-div"
                                        >

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel label='Start Date' className={`${subStartDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={subStartDate ? moment(subStartDate, 'YYYY-MM-DD').toDate() : null}
                                                            className={`form-control add-user-input`}
                                                            // dateFormat="dd/MM/yyyy"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText="Select Start Date"
                                                            onChange={(date) => handleSelectChange(date, "subStart")}
                                                        // maxDate={new Date()}
                                                        // readOnly={compaintId}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel label='End Date' className={`${subEndDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={subEndDate ? moment(subEndDate, 'YYYY-MM-DD').toDate() : null}
                                                            className={`form-control add-user-input`}
                                                            // dateFormat="dd/MM/yyyy"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText="Select End Date"
                                                            onChange={(date) => handleSelectChange(date, "subEnd")}
                                                        // maxDate={new Date()}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row add-user-image-container-div"
                                        >

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="No of users *"
                                                        className={`${noOfUsers
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="No of users *"
                                                            value={noOfUsers}
                                                            onChange={(e) => setNoOfUsers(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="BDE *"
                                                        className={`${bde
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="BDE *"
                                                            value={bde}
                                                            onChange={(e) => setBde(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div> */}
                                            {compId && filteredData && filteredData.length > 0 ? (
                                                <div className="col-lg-6 col">
                                                    <>BDE : {filteredData[0].bde}</>
                                                </div>
                                            ) : (
                                                <div className="col-lg-6 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="BDE *"
                                                            className={`${bde
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control add-user-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder="BDE *"
                                                                value={bde}
                                                                onChange={(e) => setBde(e.target.value)}
                                                                required
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className="row add-user-image-container-div"
                                        >

                                            {/* <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Leadgen *"
                                                        className={`${leadGen
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Leadgen *"
                                                            value={leadGen}
                                                            onChange={(e) => setLeadGen(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div> */}
                                            {compId && filteredData && filteredData.length > 0 ? (
                                                <div className="col-lg-6 col">
                                                    <>Leadgen : {filteredData[0].leadgen}</>
                                                </div>
                                            ) : (
                                                <div className="col-lg-6 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Leadgen *"
                                                            className={`${leadGen
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control add-user-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Leadgen *"
                                                                value={leadGen}
                                                                onChange={(e) => setLeadGen(e.target.value)}
                                                                required
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            )}
                                            {/* <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel label='First Subscription Date' className={`${firstSubDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={firstSubDate ? moment(firstSubDate, 'YYYY-MM-DD').toDate() : null}
                                                            className={`form-control add-user-input`}
                                                            // dateFormat="dd/MM/yyyy"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText="First Subscription Date"
                                                            onChange={(date) => handleSelectChange(date, "firstSubDate")}
                                                        // maxDate={new Date()}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div> */}
                                            {compId && filteredData && filteredData.length > 0 ? (
                                                <div className="col-lg-6 col">
                                                    <>First Subscription Date : {filteredData[0].firstsubdate}</>
                                                </div>
                                            ) : (
                                                <div className="col-lg-6 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel label='First Subscription Date' className={`${firstSubDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                            <DatePicker
                                                                selected={firstSubDate ? moment(firstSubDate, 'YYYY-MM-DD').toDate() : null}
                                                                className={`form-control add-user-input`}
                                                                dateFormat="yyyy-MM-dd"
                                                                placeholderText="First Subscription Date"
                                                                onChange={(date) => handleSelectChange(date, "firstSubDate")}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSubscription;