import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import SbAddBtn from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
// import AddUserData from "./AddUserData";
import "../../Styles/AddPage.scss"
import "../../Styles/AddUserDetails.scss"
import "../../Styles/Users.scss"
// import AddCustomer from "./AddCustomer";
import { useDispatch, useSelector } from "react-redux";
import { loadAllSubscriptionData } from "../../Redux/action";
import SubscriptionTable from "./SubscriptionTable";
import AddSubscription from "./AddSubscription";

function Subscription() {

    let tableHeader = [
        { name: "COMPANY ID", align: "left", width: "200px" },
        // { name: "COMPANY NAME", align: "left", width: "180px" },
        { name: "CONTACT PERSON", align: "left", width: "150px" },
        { name: "CONTACT NUMBER / EMAIL", align: "left", width: "150px" },
        // { name: "CONTACT NUMBER", align: "left", width: "150px" },
        { name: "FIRST SUBSCRIPTION DATE", align: "left", width: "180px" },
        { name: "SUBSCRIPTION START DATE", align: "left", width: "180px" },
        { name: "SUBSCRIPTION END DATE", align: "left", width: "180px" },
        { name: "LEADGEN", align: "left", width: "150px" },
        { name: "BDE", align: "left", width: "150px" },
        { name: "NO.OF USERS", align: "left", width: "120px" },
        { name: "SUBSCRIPTION DATE", align: "left", width: "150px" },
        { name: "SUBSCRIPTION TYPE", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "120px" },
        { name: "ACTION", align: "left", width: "120px" },
    ]

    let dispatch = useDispatch();
    const { getSubscriptions } = useSelector((state) => state.data);
    console.log(getSubscriptions,'getSubscriptions')
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addusermodelshow, setAddusermodelshow] = useState(false);
    const [filteredSubscription, setFilteredSubscription] = useState([]);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    useEffect(() => {
        dispatch(loadAllSubscriptionData())
    }, [])

    const [filteredData, setFilteredData] = useState([]);
    const [compId, setComId] = useState()

    const handleEdit = (id) => {
        const filtered = getSubscriptions.filter((data) => data.companyid === id);
        setFilteredData(filtered);
        setComId(id)
        setAddusermodelshow(true)
    }

    const navigate = useNavigate();
    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    const handleClose = () => {
        setAddusermodelshow(!addusermodelshow);
    };

    useEffect(() => {
        // Filter customers based on search value
        setFilteredSubscription(
            getSubscriptions.filter((customer) =>
                Object.values(customer).some(
                    (value) =>
                        typeof value === "string" &&
                        value.toLowerCase().includes(searchValue.toLowerCase())
                )
            )
        );
    }, [getSubscriptions, searchValue]);

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <div id="main-page">
            {!addusermodelshow && <>
                <div className="top-and-sidebar">
                    <SideNavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar toggleMenu={toggleMenu} menuCollapse={menuCollapse} toggleshift={toggleshift} />
                </div>

                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <div className="user-table">
                                <Row className="search-row">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToSettingPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">Subscriptions</h6>
                                        </div>
                                    </Col>
                                    <Col md={5} className="cat-col">
                                        <div className="input-div">
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                className="form-control setting-input"
                                                id="metricInfo_search_bar"
                                                value={searchValue}
                                                onChange={handleSearchInputChange}
                                            />
                                            {/* <i className="fa fa-search search-icon"></i> */}
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                {/* <div
                                                className="header-filter-btn"
                                            // onClick={() => setOpenFilterTab(!openFilterTab)}
                                            >
                                                <p className="card-head">
                                                    <i className="bi bi-sliders" />
                                                    &nbsp;&nbsp;Filters
                                                </p>
                                            </div> */}
                                                <SbAddBtn
                                                    onClickEffect={() => setAddusermodelshow(!addusermodelshow)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <SubscriptionTable
                                    tableHeader={tableHeader}
                                    // getCustomers={getCustomers}
                                    getSubscriptions={searchValue ? filteredSubscription : getSubscriptions}
                                    handleEdit={handleEdit}
                                />
                            </div>
                        </div>
                    </div >
                </div >
            </>}
            {
                addusermodelshow && (
                    <AddSubscription
                        // getUserDetails={getUserDetails}
                        addusermodelshow={addusermodelshow}
                        handleClose={handleClose}
                        compId={compId}
                        setComId={setComId}
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                    />
                )
            }
        </div >
    );
}

export default Subscription;
