// Subscription Page
export const post_Subscription_Data = "post_Subscription_Data"
export const get_allSubscription_Data = "get_allSubscription_Data"
// Subscription Page
// Signin Page
export const post_SignIn_Data = "post_SignIn_Data"
// Signin Page

// Users Page
export const post_User_Data = "post_User_Data"
export const get_allUser_Data = "get_allUser_Data"
export const get_User_Count = "get_User_Count"
export const get_allUser_DropdownData = "get_allUser_DropdownData"
export const get_allUser_DesignationDropdownData = "get_allUser_DesignationDropdownData"
export const get_allUser_ReportingDropdownData = "get_allUser_ReportingDropdownData"
export const get_Filtered_UserData = "get_Filtered_UserData"
// Users Page

// Customer Page
export const post_Customer_Data = "post_Customer_Data"
export const get_allCustomer_Data = "get_allCustomer_Data"
export const get_Customer_CompanyName = "get_Customer_CompanyName"
export const get_Filtered_CustomerData = "get_Filtered_CustomerData"
export const clear_Customers_Data = "clear_Customers_Data"
// Customer Page

// Lazy Loading
export const Get_Total_Data = "Get_Total_Data";
export const SET_NEXT_URL = 'SET_NEXT_URL';
export const get_Offset_Count = 'get_Offset_Count';
// Lazy Loading

// Branch Page
export const get_All_BranchData = "get_All_BranchData"
export const post_BranchData = "post_BranchData"
export const get_branchDropdown = "get_branchDropdown"
// Branch Page