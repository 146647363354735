import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import AddUserData from "./AddUserData";
import "../../Styles/AddPage.scss";
import "../../Styles/AddUserDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadAllUsersData, loadAllUsersDesignationDropdownData, loadAllUsersDropdownData, loadAllUsersReportingToDropdownData, loadFilteredUserData, loadUserCount } from "../../Redux/action";
import UsersTable from "./UsersTable";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import * as XLSX from "xlsx";

function Users() {
    let tableHeader = [
        { name: "Sl.No", align: "left", width: "100px" },
        { name: "NAME", align: "left", width: "120px" },
        { name: "EMAIL", align: "left", width: "150px" },
        { name: "PHONE", align: "left", width: "150px" },
        { name: "DESIGNATION", align: "left", width: "150px" },
        { name: "REPORTINGTO", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "150px" },
        { name: "ACTION", align: "left", width: "150px" },
    ];

    const dispatch = useDispatch();
    const { getUsers } = useSelector((state) => state.data);
    const { getUserCount } = useSelector((state) => state.data);
    const { getUserDropdown } = useSelector((state) => state.data);
    const { getUserDesignationDropdown } = useSelector((state) => state.data);
    const { getUserReportingToDropdown } = useSelector((state) => state.data);
    const { getFilteredUserData } = useSelector((state) => state.data);
    const { offsetCount } = useSelector((state) => state.data);

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addusermodelshow, setAddusermodelshow] = useState(false);
    const [openFilterTab, setOpenFilterTab] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [PageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [filterArray, setFilterArray] = useState([])
    const [users, setUsers] = useState({ value: "", label: "" })
    const [designation, setDesignation] = useState({ value: "", label: "" })
    const [reportingTo, setReportingTo] = useState({ value: "", label: "" })
    const [status, setStatus] = useState({ value: "", label: "" });
    const [hasMoreData, setHasMoreData] = useState(true);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };


    useEffect(() => {
        dispatch(loadUserCount());
        dispatch(loadAllUsersDropdownData());
        dispatch(loadAllUsersDesignationDropdownData());
        dispatch(loadAllUsersReportingToDropdownData());
    }, [dispatch]);

    // useEffect(() => {
    //     fetchUsers();
    // }, [offset]);

    // const fetchUsers = () => {
    //     dispatch(loadAllUsersData(PageSize, offset, setIsLoading, setLoading));
    // };

    // useEffect(() => {
    //     fetchUserFilterdData();
    // }, [offset, filterArray]);

    // const fetchUserFilterdData = () => {
    //     dispatch(loadFilteredUserData(filterArray, PageSize, offset, setIsLoading, setLoading))
    // }
    // useEffect(() => {
    //     if (filterArray) {
    //         setOffset(0);
    //     }
    // }, [filterArray]);

    const fetchUsers = () => {
        if (filterArray.length > 0) {
            dispatch(loadFilteredUserData(filterArray, PageSize, offset, setIsLoading, setLoading, setHasMoreData, true));
        } else {
            dispatch(loadAllUsersData(PageSize, offset, setIsLoading, setLoading, setHasMoreData));
        }
    };

    useEffect(() => {
        // Reset offset to 0 and fetch data when filterArray changes
        setOffset(0);
        setHasMoreData(true); // Reset hasMoreData when filter changes
    }, [filterArray]);

    useEffect(() => {
        fetchUsers();
    }, [offset, filterArray]);


    const [userId, setUserId] = useState();
    const [filteredUserData, setFilteredUserData] = useState([]);

    const handleEdit = (id) => {
        const filteredData = getUsers?.filter((data) => data.id === id);
        setFilteredUserData(filteredData);
        setUserId(id);
        setAddusermodelshow(true);
    };

    useEffect(() => {
        setFilteredUsers(
            getUsers?.filter((customer) =>
                Object.values(customer).some(
                    (value) =>
                        typeof value === "string" &&
                        value.toLowerCase().includes(searchValue.toLowerCase())
                )
            )
        );
    }, [getUsers, searchValue]);

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const navigate = useNavigate();
    const goToSettingPage = () => navigate(-1);

    const handleClose = () => {
        setAddusermodelshow(!addusermodelshow);
    };

    const handleDropdownFilter = (data, type) => {
        let list = [];
        if (type === "users") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "NAME");
            }
            list.push({ key: "NAME", value: data.value });
            setUsers(data);
        } else if (type === "designation") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "DESIGNATION");
            }
            list.push({ key: "DESIGNATION", value: data.value });
            setDesignation(data);
        } else if (type === "reportingTo") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "REPORTINGTO");
            }
            list.push({ key: "REPORTINGTO", value: data.value });
            setReportingTo(data);
        } else if (type === "status") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "STATUS");
            }
            list.push({ key: "STATUS", value: data.value });
            setStatus(data);
        }
        setFilterArray(list);
        setOffset(0); // Reset offset when filter changes
        setHasMoreData(true);
        dispatch(loadFilteredUserData(list, PageSize, 0, setIsLoading, setLoading, setHasMoreData, false));
    };

    const exportUserDetails = () => {
        const dataToExport = filterArray.length > 0
            ? getFilteredUserData
            : getUsers;
        const ws = XLSX.utils.json_to_sheet(dataToExport.map((data) => ({
            "Sl.No": data.rn,
            "NAME": data.name,
            "EMAIL": data.email,
            "PHONE": data.phonenumber,
            "DESIGNATION": data.designation,
            "REPORTINGTO": data.reportingto,
            "STATUS": data.status,
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "UsersData");
        XLSX.writeFile(wb, "UsersData.xlsx");
    };

    const clearFilters = () => {
        setFilterArray([]);
        setUsers({ value: "", label: "" });
        setDesignation({ value: "", label: "" });
        setReportingTo({ value: "", label: "" });
        setStatus({ value: "", label: "" });
        // fetchUsers(offset);
    };

    return (
        <div id="main-page">
            {!addusermodelshow && (
                <>
                    <div className="top-and-sidebar">
                        <SideNavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                        <Topbar toggleMenu={toggleMenu} menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    </div>

                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content">
                                <div className="user-table">
                                    <Row className="search-row">
                                        <Col md={3} className="cat-col">
                                            <div className="page-header-text-div">
                                                <div className="back-btn" onClick={goToSettingPage}>
                                                    <img
                                                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                        alt="Back"
                                                    />
                                                </div>
                                                <h6 className="page-header-text">Users({getUserCount.length > 0 && getUserCount[0]["count(*)"]})</h6>
                                            </div>
                                        </Col>
                                        <Col md={5} className="cat-col">
                                            <div className="input-div">
                                                <input
                                                    type="search"
                                                    placeholder="Search..."
                                                    className="form-control setting-input"
                                                    id="metricInfo_search_bar"
                                                    value={searchValue}
                                                    onChange={handleSearchInputChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4} className="cat-col">
                                            <div className="search-bar-header" style={{ float: "right" }}>
                                                <div className="page-top-bar-dropdowns page-top-Filters">
                                                    <div
                                                        className="header-filter-btn"
                                                        onClick={() => setOpenFilterTab(!openFilterTab)}
                                                    >
                                                        <p className="card-head">
                                                            <i className="bi bi-sliders" />
                                                            &nbsp;&nbsp;Filters
                                                        </p>
                                                    </div>
                                                    <SbAddBtn
                                                        onClickEffect={() => setAddusermodelshow(!addusermodelshow)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {openFilterTab && (
                                        <div className="page-filter-section">
                                            <div style={{ width: "100%" }}>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <div style={{ width: "100%", padding: "0px 5px" }}>
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={getUserDropdown}
                                                                    value={users.value && users}
                                                                    onChange={(data) =>
                                                                        handleDropdownFilter(data, "users")
                                                                    }
                                                                    placeholder="Select User"
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div style={{ width: "100%", padding: "0px 5px" }}>
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={getUserDesignationDropdown}
                                                                    value={designation.value && designation}
                                                                    onChange={(data) =>
                                                                        handleDropdownFilter(data, "designation")
                                                                    }
                                                                    placeholder="Select Designation"
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div style={{ width: "100%", padding: "0px 5px" }}>
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={getUserReportingToDropdown}
                                                                    value={reportingTo.value && reportingTo}
                                                                    onChange={(data) =>
                                                                        handleDropdownFilter(data, "reportingTo")
                                                                    }
                                                                    placeholder="Select ReportingTo"
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div style={{ width: "100%", padding: "0px 5px" }}>
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={optionsStatusList}
                                                                    value={status.value && status}
                                                                    onChange={(data) =>
                                                                        handleDropdownFilter(data, "status")
                                                                    }
                                                                    placeholder="Select Status"
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <SbCancelBtn
                                                                btnName="Clear"
                                                                onClickEffect={clearFilters}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    )}
                                    <UsersTable
                                        tableHeader={tableHeader}
                                        // getUsers={searchValue ? filteredUsers : getUsers}
                                        // getUsers={filterArray.length > 0 ? getFilteredUserData : getUsers}
                                        getUsers={
                                            searchValue
                                                ? filteredUsers
                                                : filterArray.length > 0
                                                    ? getFilteredUserData
                                                    : getUsers
                                        }
                                        handleEdit={handleEdit}
                                        loading={loading}
                                        setPageSize={setPageSize}
                                        PageSize={PageSize}
                                        setLoading={setLoading}
                                        isLoading={isLoading}
                                        setOffset={setOffset}
                                        offset={offset}
                                        fetchData={() => fetchUsers(offset)}
                                        hasMoreData={hasMoreData}
                                        exportUserDetails={exportUserDetails}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {addusermodelshow && (
                <AddUserData
                    addusermodelshow={addusermodelshow}
                    handleClose={handleClose}
                    filteredUserData={filteredUserData}
                    userId={userId}
                />
            )}
        </div>
    );
}

export default Users;
