import React, { useEffect, useState } from "react";
import travelizeSalesBeeLogo from "../Images/metricinfo-payment-logo.svg";
import "../../src/Styles/Signin.scss";
import "../Styles/CommonStyle.scss";
import { Link } from "react-router-dom";
import travelize from "../../src/Images/metric-favicon.svg";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Form, Image, Col, Carousel } from "react-bootstrap";
// import { NumberValidation } from "../ValidationFormat";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//     loadCountryCodes,
//     loadReferredByDropdowndata,
// } from "../../redux/action";
import { toast } from "react-toastify";
import moment from "moment";

const Signup = () => {
    let themeColor = localStorage.getItem("theme");
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const { getReferredByDropdownData } = useSelector((state) => state.data);
    const { countryCodes } = useSelector((state) => state.data);

    const todayDate = new Date();
    const futureDate = new Date(todayDate.getTime() + 7 * 24 * 60 * 60 * 1000);

    const uniqueID =
        Math.floor(Math.random() * 10000) + Math.floor(Math.random() * 5000);

    // useEffect(() => {
    //     dispatch(loadReferredByDropdowndata());
    //     dispatch(loadCountryCodes());
    // }, []);

    const location = useLocation();
    const [locationPathNames, setLocationPathNames] = useState([]);
    const [showReferredByDropdown, setShowReferredByDropdown] = useState(true);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const nameParam = searchParams.get("name");
        setLocationPathNames(location.pathname.split("/"));

        if (nameParam) {
            setShowReferredByDropdown(false);
        } else {
            setShowReferredByDropdown(true);
        }
    }, [location]);

    function getUrlParameter(name) {
        const urlParams = new URLSearchParams(
            window.location.search + window.location.hash
        );
        return urlParams.get(name);
    }

    // Get the 'name' parameter from the URL
    const nameFromUrl = getUrlParameter("name");

    const pathname = location.pathname;

    // Define placeholder text based on the pathname
    let placeholderText = "";
    switch (pathname) {
        case "/Trial":
            placeholderText = "Source";
            break;
        case "/Trial/BDE":
            placeholderText = "Referred By";
            break;
        // Add more cases for other pathnames
        default:
            placeholderText = "Source";
    }

    const [options, setOptions] = useState([]);

    useEffect(() => {
        // Update dropdown options based on the pathname
        const pathname = location.pathname;

        switch (pathname) {
            case "/signup":
                const trialOptions = [
                    { value: "Website", label: "Website" },
                    { value: "Email", label: "Email" },
                    { value: "BusinessTeam", label: "Business Team" },
                    { value: "SocialMedia", label: "Social Media" },
                ];
                setOptions(trialOptions);
                break;
            case "/Trial/BDE":
                // Use options from the state data
                setOptions(getReferredByDropdownData);
                break;
            // Add more cases for other pathnames
            default:
                // Use the default options
                setOptions([]);
        }
    }, [location.pathname, getReferredByDropdownData]);

    const [companyName, setCompanyName] = useState("");
    const [clientName, setClientName] = useState("");
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState({
        label: "+91",
        value: "+91",
    });
    const [contactNo, setContactNo] = useState(null);
    const [referredBy, setReferredBy] = useState({
        value: "",
        label: "",
    });
    const [captchaVerification, setCaptchaVerification] = useState(false);

    let currentYear = new Date().getFullYear();

    const onChange = (value) => {
        // console.log("CAPTCHA:", value);
        if (value !== "" && value !== null) {
            setCaptchaVerification(true);
        }
    };

    const handleReferredByChange = (selectedOption) => {
        setReferredBy(selectedOption.label);
    };

    const createSubscription = (e) => {
        if (captchaVerification) {
            if (email && companyName && contactNo && countryCode && referredBy) {
                const subscriptionData = {
                    companyid: uniqueID,
                    companyname: companyName,
                    contactperson: clientName,
                    emailid: email,
                    phonenumber: contactNo,
                    subscriptiondate: moment(todayDate).format("YYYY-MM-DD"),
                    subtype: "Trail",
                    startdate: moment(todayDate).format("YYYY-MM-DD"),
                    enddate: moment(futureDate).format("YYYY-MM-DD"),
                    numberofusers: 5,
                    bde: "Mallik",
                    leadgen: referredBy,
                    status: "Active",
                    firstsubdate: moment(todayDate).format("YYYY-MM-DD"),
                    password: `MetricInfo_OL${currentYear}`,
                    countryCode: countryCode.value,
                };

                let requestoption = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(subscriptionData),
                };

                fetch(`https://apex.oracle.com/pls/apex/lobotus/subapi/Sub_api`, requestoption)
                    .then((res) => res.json())
                    .then((res) => {
                        console.log("res", res.status === "success");
                        if (res.status === "success") {
                            toast.success("Your Subscription has Created Successfully");
                        } else {
                            toast.error(res.message);
                        }
                    });
            } else {
                toast.error("Please fill all the fields");
            }
        } else {
            toast.error("Captcha is Required");
        }
        e.preventDefault();
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="sigin-page-body">
                    <div className="dflex-flow-row">
                        <Col lg={5} md={5} sm={12} className="signIn-container-left-div">
                            <div className="signIn-container-logo">
                                <Image
                                    src={travelizeSalesBeeLogo}
                                    alt="MetricInfo"
                                    className="signIn-logo"
                                />
                            </div>
                            <div className="signIn-container-gif">
                                <Image
                                    src="../images/sign-animate.gif"
                                    alt="MetricInfo Motion Logo"
                                    className="signIn-moving-img"
                                />
                                <div className="text-sliding-below-img">
                                    <Carousel
                                        style={{ width: "100%" }}
                                        controls={false}
                                        indicators={false}
                                        interval={3000}
                                    >
                                        <Carousel.Item>
                                            <h6>
                                                <b>Geolocation Monitoring</b>
                                            </h6>
                                            <p>Real-time tracking to determine precise location</p>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <h6>
                                                <b>Reporting and Analytics</b>
                                            </h6>
                                            <p>
                                                Offering valuable insights for informed decision-making
                                            </p>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <h6>
                                                <b>Visits and Expenses</b>
                                            </h6>
                                            <p>
                                                Ensuring optimal resource allocation and cost control
                                            </p>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                            <div className="devider">
                                <div className="v1"></div>
                            </div>
                        </Col>
                        <Col lg={5} md={5} sm={12} className="signIn-container-right-div">
                            <div className="metricinfo-logo">
                                <Image
                                    src={travelize}
                                    alt="MetricInfo Logo"
                                    className="sb-trv-logo"
                                />
                            </div>
                            <div className="signin-header signup-part">
                                <div className="signup-header">
                                    <h2>Sign Up</h2>
                                    <div className="form-title">
                                        Please fill in this form to create an account!
                                    </div>
                                </div>
                                <Form onSubmit={createSubscription} className="login-form">
                                    <div className="form-group">
                                        <div className="icon-with-input before">
                                            <i className="fa fa-building" />
                                            <input
                                                type="text"
                                                className="form-control signIn-form-control"
                                                placeholder="Company Name"
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mt3">
                                        <div className="icon-with-input before">
                                            <i className="fa fa-id-card" />
                                            <input
                                                type="text"
                                                className="form-control signIn-form-control"
                                                placeholder="Contact Person"
                                                onChange={(e) => setClientName(e.target.value)}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mt3">
                                        <div className="icon-with-input before">
                                            <i className="fa fa-envelope"></i>
                                            <input
                                                type="email"
                                                className="form-control signIn-form-control"
                                                placeholder="Enter your email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mt3">
                                        <div className="contact-input hv-center">
                                            <div className="signup-contry-codes-div">
                                                <Select
                                                    name="countrycode"
                                                    className="cntry-code"
                                                    classNamePrefix="react-select-list"
                                                    // defaultValue={countryCodes[0]}
                                                    options={countryCodes}
                                                    placeholder="Code"
                                                    value={countryCode}
                                                    // onInputChange={countryCodes.label}
                                                    onChange={(data) => setCountryCode(data)}
                                                    isSearchable={true}
                                                    autoComplete="off"
                                                    required
                                                />
                                            </div>
                                            <div
                                                style={{ width: "100%", marginLeft: "10px" }}
                                                className="icon-with-input before"
                                            >
                                                <i className="fa fa-phone" />
                                                <input
                                                    type="tel"
                                                    className="form-control signIn-form-control"
                                                    placeholder="Mobile Number"
                                                    maxLength={countryCode.value == "+91" ? 10 : 15}
                                                    minLength={countryCode.value == "+91" ? 10 : 5}
                                                    value={contactNo}
                                                    onChange={(e) => setContactNo(e.target.value)}
                                                    // onKeyDown={NumberValidation}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt3" style={{ width: "100%" }}>
                                        {showReferredByDropdown && (
                                            <div className="icon-with-select sbefore signup-contry-codes-div">
                                                <i className="fa fa-globe" />
                                                <Select
                                                    className="source-creation m-0"
                                                    classNamePrefix="react-select-list"
                                                    placeholder={placeholderText}
                                                    options={options}
                                                    value={referredBy.value && referredBy}
                                                    onChange={handleReferredByChange}
                                                    isSearchable={true}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="recaptcha-part">
                                        <ReCAPTCHA
                                            sitekey="6LcC984nAAAAAA3eYGW7xlxb12ooQRUCu1TkFHum"
                                            className="recaptch-modal-kit"
                                            onChange={onChange}
                                            size={"normal"}
                                        />
                                    </div>
                                    <div className="signin-button mt10 hv-center">
                                        <Button
                                            type="submit"
                                            className="btn btn-signin-button signup"
                                        >
                                            Sign up
                                        </Button>
                                    </div>
                                    <div className="back-to-signin mt-1">
                                        Do you have an account ?&nbsp;
                                        <Link to="/" className="color-green">
                                            <b>Sign In</b>
                                        </Link>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;

