import * as types from "./actionType";
import * as swal from "../Components/SwalAlert"
import * as HeaderAuthentication from "../Components/HeaderAuthentication";

// Subscription Page
const postSubscriptionData = () => ({
    type: types.post_Subscription_Data,
});
const getAllSubscriptions = (getSubscriptions) => ({
    type: types.get_allSubscription_Data,
    payload: getSubscriptions
});
// Subscription Page
// SignIn Page
const postSignInData = () => ({
    type: types.post_SignIn_Data,
});
// SignIn Page
// Users Page
const postUserData = () => ({
    type: types.post_User_Data,
});
const getAllUsers = (getUsers) => ({
    type: types.get_allUser_Data,
    payload: getUsers
});
const getUserCount = (getUserCount) => ({
    type: types.get_User_Count,
    payload: getUserCount
});
const getAllUsersDropdownData = (getUserDropdown) => ({
    type: types.get_allUser_DropdownData,
    payload: getUserDropdown
});
const getAllUsersDesignationDropdownData = (getUserDesignationDropdown) => ({
    type: types.get_allUser_DesignationDropdownData,
    payload: getUserDesignationDropdown
});
const getAllUsersReportinToDropdownData = (getUserReportingToDropdown) => ({
    type: types.get_allUser_ReportingDropdownData,
    payload: getUserReportingToDropdown
});
const getFilteredUserData = (getFilteredUserData, append = false) => ({
    type: types.get_Filtered_UserData,
    payload: getFilteredUserData,
    append: append
});
// Users Page1
// Customer page
const postCustomerData = () => ({
    type: types.post_Customer_Data,
});
export const getAllCustomers = (getCustomers) => ({
    type: types.get_allCustomer_Data,
    payload: getCustomers,
});
const getCustomerCompanyName = (getCustomerCompanyName) => ({
    type: types.get_Customer_CompanyName,
    payload: getCustomerCompanyName
});
const getFilteredCustomerData = (getFilteredCustomerData, append = false) => ({
    type: types.get_Filtered_CustomerData,
    payload: getFilteredCustomerData,
    append: append
});
export const clearCustomersData = () => ({
    type: types.clear_Customers_Data,
});
// Customer page
// Lazy Loading
export const getToataldataCount = (totalCount) => ({
    type: types.Get_Total_Data,
    payload: totalCount,
});
export const getNextDataUrl = (nextUrl) => ({
    type: types.SET_NEXT_URL,
    payload: nextUrl,
});
export const getOffsetCount = (offsetCount) => ({
    type: types.get_Offset_Count,
    payload: offsetCount,
});
// Lazy Loading

// Branch Page
export const getAllBrachData = (getAllBrachData) => ({
    type: types.get_All_BranchData,
    payload: getAllBrachData
})
export const getBranchDropdown = (getBranchDropdown) => ({
    type: types.get_branchDropdown,
    payload: getBranchDropdown
})
export const postBranchData = () => ({
    type: types.post_BranchData
})
// Branch Page

// Subscription Page
export const addSubscriptionData = (postData, closeModel, customerId) => {
    return function (dispatch) {
        if (customerId) {
            fetch(` https://apex.oracle.com/pls/apex/lobotus/Update Company/Updatecompany`, postData)
                // .then((resp) => resp.json())
                .then((res) => {
                    // if (res) {
                    //     dispatch(postSubscriptionData());
                    //     closeModel();
                    swal.showAlert("Success", "Updated Successfully", "Success");
                    closeModel();
                    if (customerId) {
                        dispatch(loadAllSubscriptionData())
                    }
                    // } else {
                    //     closeModel();
                    //     swal.showAlert("Failed", "Failed to Add", "Failed");
                    // }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    swal.showAlert("Failed", "Failed to Update", "Failed");
                    closeModel();
                });
        } else {
            fetch(` https://apex.oracle.com/pls/apex/lobotus/subapi/Sub_api`, postData)
                // .then((resp) => resp.json())
                .then(() => {
                    // if (res.status === "success") {
                    //     dispatch(postSubscriptionData());
                    //     closeModel();
                    swal.showAlert("Success", "Added Successfully", "Success");
                    closeModel();
                    if (customerId) {
                        dispatch(loadAllSubscriptionData())
                    }
                    // } else {
                    //     closeModel();
                    //     swal.showAlert("Failed", "Failed to Add", "Failed");
                    // }
                }
                )
                .catch((error) => {
                    console.error('Error:', error);
                    swal.showAlert("Failed", "Failed to Add", "Failed");
                    closeModel();
                });

        }

    }
};
export const loadAllSubscriptionData = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/Update Company/Updatecompany`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                dispatch(getAllSubscriptions(res.items))
            })
            .catch((err) => { console.log(err) })
    }
}
// Subscription Page


export const addSignInData = (postData, setSuccessMessage, setErrorMessage, rememberMe, email, password, navigateToDashboard) => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/bkndli/login`, postData)
            .then((resp) => resp.json())
            .then((res) => {
                if (res.status === "success") {
                    dispatch(postSignInData());
                    // setSuccessMessage("Sign in successful!");
                    setSuccessMessage(res.message);
                    setErrorMessage("");
                    sessionStorage.setItem("status", "success");
                    if (rememberMe) {
                        const credentials = { email: email, password: password, remember: rememberMe, };
                        localStorage.setItem("rememberedCredentials", JSON.stringify(credentials));
                    } else {
                        localStorage.removeItem("rememberedCredentials");
                    }
                    // window.location.href = "/";
                    navigateToDashboard();
                } else {
                    // setSuccessMessage("Failed to sign in. Please try again.");
                    setSuccessMessage("");
                    setErrorMessage(res.message);
                }
            });
    }
};

// Users Page
export const addUserData = (postData, closeModel, userId) => {
    return function (dispatch) {
        if (userId) {
            fetch(`https://apex.oracle.com/pls/apex/lobotus/update/update/${userId}`, postData)
                // .then((resp) => resp.json())
                .then((res) => {
                    //     if (res.status === "success") {
                    dispatch(postUserData());
                    closeModel();
                    swal.showAlert("Success", "Updated Successfully", "Success");
                    if (userId) {
                        dispatch(loadAllUsersData())
                    }

                    //     } else {
                    //         closeModel();
                    //         swal.showAlert("Failed", "Failed to Update", "Failed");
                    //     }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    swal.showAlert("Failed", "Failed to Update", "Failed");
                    closeModel();
                });
        } else {
            // fetch(`https://apex.oracle.com/pls/apex/lobotus/executives/Api`, postData)
            fetch(`https://apex.oracle.com/pls/apex/lobotus/AddDelexe/AddDel`, postData)
                .then((resp) => resp.json())
                .then((res) => {
                    if (res.status === "success") {
                        dispatch(postUserData());

                        swal.showAlert("Success", "Added Successfully", "Success");
                        // if (userId) {
                        dispatch(loadAllUsersData())
                        closeModel();
                        // }
                    } else {
                        closeModel();
                        swal.showAlert("Failed", "Failed to Add", "Failed");
                    }
                });
        }
    }
};

// export const loadAllUsersData = (
//     PageSize,
//     currentPage,
//     offset,
//     setIsLoading,
//     setLoading
// ) => {
//     return function (dispatch) {
//         const offset = (currentPage - 1) * PageSize;
//         fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /fetcgdata?PageSize=${PageSize}&CurrentPage=${currentPage}&Offset=${offset}&limit=${PageSize}`, HeaderAuthentication.getRequestOptions)
//             .then((res) => { return res.json() })
//             .then((res) => {
//                 dispatch(getAllUsers(res.items))
//                 dispatch(getToataldataCount(res.count));
//                 if (setIsLoading) {
//                     setIsLoading(false);
//                 }
//                 if (setLoading) {
//                     setLoading(false);
//                 }
//             })
//             .catch((err) => { console.log(err) })
//     }
// }

export const loadAllUsersData = (PageSize, offset, setIsLoading, setLoading) => {
    // let param = "";
    // if (filterArray.length !== 0) {
    //     filterArray.map((item) => {
    //         return (param = param + "&" + item.key + "=" + item.value)
    //     })
    // }
    return function (dispatch) {
        // Fetch data from the API with offset and PageSize
        fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /fetcgdata?limit=${PageSize}&offset=${offset}`)
            .then((res) => res.json())
            .then((data) => {
                // Dispatch actions to update Redux state
                dispatch(getAllUsers(data.items));
                dispatch(getOffsetCount(data.offset));

                // Optionally set loading state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }

                // Handle pagination for the next URL
                if (data.links && data.links.find(link => link.rel === 'next')) {
                    // If there's a next page, you might want to store the URL for future requests
                    dispatch(getNextDataUrl(data.links.find(link => link.rel === 'next').href));
                } else {
                    // No more pages available
                    dispatch(getNextDataUrl(null));
                }
            })
            .catch((err) => {
                console.log(err);
                // Optionally handle error state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
            });
    }
}


export const loadUserCount = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/update/exe`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                dispatch(getUserCount(res.items))
            })
            .catch((err) => { console.log(err) })
    }
}
// export const loadFilteredUserData = (filterArray) => {
//     let param = "";
//     if (filterArray.length !== 0) {
//         filterArray.map((item, i) => {
//             return (param = param + "&" + item.key + "=" + item.value);
//         });
//     }
//     return function (dispatch) {
//         fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /get_filter?${param}`, HeaderAuthentication.getRequestOptions)
//             .then((res) => { return res.json() })
//             .then((res) => {
//                 console.log("API Response:", res);
//                 dispatch(getFilteredUserData(res.items))
//             })
//             .catch((err) => { console.log(err) })
//     }
// }

export const loadFilteredUserData = (filterArray, PageSize, offset, setIsLoading, setLoading, setHasMoreData, append = false) => {
    let param = "";
    if (filterArray.length !== 0) {
        filterArray.map((item, i) => {
            return (param = param + "&" + item.key + "=" + item.value);
        });
    }
    return function (dispatch) {
        // fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /get_filter?offset=${offset}${param}`)
        fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /fetcgdata?limit=${PageSize}&offset=${offset}${param}`)
            .then((res) => res.json())
            .then((data) => {
                // Dispatch actions to update Redux state
                dispatch(getFilteredUserData(data.items, append));
                dispatch(getOffsetCount(data.offset));
                // Optionally set loading state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
                if (data.hasMore !== undefined) {
                    setHasMoreData(data.hasMore);
                }

                // Handle pagination for the next URL
                if (data.links && data.links.find(link => link.rel === 'next')) {
                    // If there's a next page, you might want to store the URL for future requests
                    dispatch(getNextDataUrl(data.links.find(link => link.rel === 'next').href));
                } else {
                    // No more pages available
                    dispatch(getNextDataUrl(null));
                }
            })
            .catch((err) => {
                console.log(err);
                // Optionally handle error state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
            });
    };
};

export const loadAllUsersDropdownData = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /fetcgdata`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                dispatch(getAllUsersDropdownData(res.items.map((data) => {
                    return { value: data.name, label: data.name }
                })))
            })
            .catch((err) => { console.log(err) })
    }
}
export const loadAllUsersDesignationDropdownData = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /fetcgdata`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                // Create a Set to track unique designations
                const uniqueDesignations = new Set();
                const filteredData = res.items.filter((data) => {
                    // If the designation is not already in the Set, add it and include the item
                    if (!uniqueDesignations.has(data.designation)) {
                        uniqueDesignations.add(data.designation);
                        return true;
                    }
                    return false;
                });

                // Map the filtered data to the desired format
                const dropdownData = filteredData.map((data) => ({
                    value: data.designation,
                    label: data.designation
                }));

                // Dispatch the data
                dispatch(getAllUsersDesignationDropdownData(dropdownData));
            })
            .catch((err) => { console.log(err) })
    }
}
export const loadAllUsersReportingToDropdownData = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/get all /fetcgdata`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                // Create a Set to track unique designations
                const uniqueDesignations = new Set();
                const filteredData = res.items.filter((data) => {
                    // If the designation is not already in the Set, add it and include the item
                    if (!uniqueDesignations.has(data.reportingto)) {
                        uniqueDesignations.add(data.reportingto);
                        return true;
                    }
                    return false;
                });

                // Map the filtered data to the desired format
                const dropdownData = filteredData.map((data) => ({
                    value: data.reportingto,
                    label: data.reportingto
                }));

                // Dispatch the data
                dispatch(getAllUsersReportinToDropdownData(dropdownData));
            })
            .catch((err) => { console.log(err) })
    }
}
// Users Page

// Customer Page
export const addCustomerData = (postData, closeModel, customerId, fetchCustomer) => {
    return function (dispatch) {
        if (customerId) {
            fetch(` https://apex.oracle.com/pls/apex/lobotus/CURD/api`, postData)
                // .then((resp) => resp.json())
                .then((res) => {
                    // if (res) {
                    //     dispatch(postCustomerData());
                    //     closeModel();
                    swal.showAlert("Success", "Updated Successfully", "Success");
                    closeModel();
                    if (customerId) {
                        // dispatch(loadAllCustomersData())
                        fetchCustomer()
                    }
                    // } else {
                    //     closeModel();
                    //     swal.showAlert("Failed", "Failed to Add", "Failed");
                    // }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    swal.showAlert("Failed", "Failed to Update", "Failed");
                    closeModel();
                });
        } else {
            fetch(` https://apex.oracle.com/pls/apex/lobotus/CURD/api`, postData)
                // .then((resp) => resp.json())
                .then(() => {
                    // if (res.status === "success") {
                    //     dispatch(postUserData());
                    //     closeModel();
                    swal.showAlert("Success", "Added Successfully", "Success");
                    closeModel();
                    // if (customerId) {
                    // dispatch(loadAllCustomersData())
                    fetchCustomer();
                    // }
                    // } else {
                    //     closeModel();
                    //     swal.showAlert("Failed", "Failed to Add", "Failed");
                    // }
                }
                )
                .catch((error) => {
                    console.error('Error:', error);
                    swal.showAlert("Failed", "Failed to Add", "Failed");
                    closeModel();
                });

        }

    }
};
export const loadAllCustomersData = (PageSize, offset, setIsLoading, setLoading, setHasMoreData) => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/CURD/api?limit=${PageSize}&offset=${offset}`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                dispatch(getAllCustomers(res.items));
                dispatch(getOffsetCount(res.offset));
                // Optionally set loading state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
                if (res.hasMore !== undefined) {
                    setHasMoreData(res.hasMore);
                }

                // Handle pagination for the next URL
                if (res.links && res.links.find(link => link.rel === 'next')) {
                    // If there's a next page, you might want to store the URL for future requests
                    dispatch(getNextDataUrl(res.links.find(link => link.rel === 'next').href));
                } else {
                    // No more pages available
                    dispatch(getNextDataUrl(null));
                }
            })
            .catch((err) => {
                console.log(err);
                // Optionally handle error state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
            });
    }
}
export const loadCustomerCompanyDropdown = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/CURD/api`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                const uniqueDesignations = new Set();
                const filteredData = res.items.filter((data) => {
                    // If the designation is not already in the Set, add it and include the item
                    if (!uniqueDesignations.has(data.companyname)) {
                        uniqueDesignations.add(data.companyname);
                        return true;
                    }
                    return false;
                });

                // Map the filtered data to the desired format
                const dropdownData = filteredData.map((data) => ({
                    value: data.companyname,
                    label: data.companyname
                }));

                dispatch(getCustomerCompanyName(dropdownData))
            })
            .catch((err) => { console.log(err) })
    }
}
export const loadFilteredCustomerData = (filterArray, PageSize, offset, setIsLoading, setLoading, setHasMoreData, append = false) => {
    let param = "";
    if (filterArray.length !== 0) {
        filterArray.map((item, i) => {
            return (param = param + "&" + item.key + "=" + item.value);
        });
    }
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/CURD/api?limit=${PageSize}&offset=${offset}${param}`)
            .then((res) => res.json())
            .then((data) => {
                // Dispatch actions to update Redux state
                dispatch(getFilteredCustomerData(data.items, append));
                dispatch(getOffsetCount(data.offset));
                // Optionally set loading state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
                if (data.hasMore !== undefined) {
                    setHasMoreData(data.hasMore);
                }

                // Handle pagination for the next URL
                if (data.links && data.links.find(link => link.rel === 'next')) {
                    // If there's a next page, you might want to store the URL for future requests
                    dispatch(getNextDataUrl(data.links.find(link => link.rel === 'next').href));
                } else {
                    // No more pages available
                    dispatch(getNextDataUrl(null));
                }
            })
            .catch((err) => {
                console.log(err);
                // Optionally handle error state
                if (setIsLoading) {
                    setIsLoading(false);
                }
                if (setLoading) {
                    setLoading(false);
                }
            });
    };
};

// Customer Page

// Branch Page
export const loadAllBranchData = (filterArray, PageSize, offset, setIsLoading, setLoading) => {
    let param = "";
    if (filterArray.length !== 0) {
        filterArray.map((item) => {
            return (param = param + "&" + item.key + "=" + item.value)
        })
    }
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/Branchapi/get?limit=${PageSize}&offset=${offset}${param}`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                dispatch(getAllBrachData(res.items))
                dispatch(getToataldataCount(res.count));
            })
            .catch((err) => { console.log(err) })
    }
}
export const loadBranchDropdown = () => {
    return function (dispatch) {
        fetch(`https://apex.oracle.com/pls/apex/lobotus/Branchapi/get`, HeaderAuthentication.getRequestOptions)
            .then((res) => { return res.json() })
            .then((res) => {
                const uniqueDesignations = new Set();
                const filteredData = res.items.filter((data) => {
                    // If the designation is not already in the Set, add it and include the item
                    if (!uniqueDesignations.has(data.branch_name)) {
                        uniqueDesignations.add(data.branch_name);
                        return true;
                    }
                    return false;
                });

                // Map the filtered data to the desired format
                const dropdownData = filteredData.map((data) => ({
                    value: data.branch_name,
                    label: data.branch_name
                }));
                dispatch(getBranchDropdown(dropdownData))
            })
            .catch((err) => { console.log(err) })
    }
}

export const addBranchData = (postData, closeModel, branchId, fetchBranchData) => {
    return function (dispatch) {
        if (branchId) {
            fetch(`https://apex.oracle.com/pls/apex/lobotus/Branchapi/Update`, postData)
                // .then((resp) => resp.json())
                .then((res) => {
                    // if (res.status === "success") {
                    // dispatch(postBranchData());

                    swal.showAlert("Success", "Updated Successfully", "Success");
                    if (branchId) {
                        // dispatch(loadAllBranchData())
                        fetchBranchData()
                        closeModel();
                    }
                });
        } else {
            fetch(`https://apex.oracle.com/pls/apex/lobotus/Branchapi/Add`, postData)
                // .then((resp) => resp.json())
                .then((res) => {
                    // if (res.status === "success") {
                    // dispatch(postBranchData());

                    swal.showAlert("Success", "Added Successfully", "Success");
                    // if (userId) {
                    // dispatch(loadAllBranchData())
                    fetchBranchData()
                    closeModel();
                    // }

                });
        }
    }
}
// Branch Page

