import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addUserData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddRefund = (props) => {

    let dispatch = useDispatch();
    const [fullName, setFullName] = useState("");
    const [customerId, setCustomerId] = useState();
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState();
    const [contactNum, setContactNum] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [transactionDate, setTransactionDate] = useState(new Date());
    const [transactionAmount, setTransactionAmount] = useState("");
    const [reasonForRefund, setResonForRefund] = useState("");

    const handleSelectChange = (date) => {
        setTransactionDate(date);
    };

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div show={props.addCashModel} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Deposite Details</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>Deposite Details</h4>
                            <p>Please enter deposite infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                        // onSubmit={handleUserData}
                        >
                            <div className="row">

                                <div className="col-lg-12 col">
                                    <div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Customer Id *"
                                                        className={`${customerId
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Customer Id *"
                                                            value={customerId}
                                                            onChange={(e) => {
                                                                setCustomerId(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Full Name *"
                                                        className={`${fullName
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Full Name *"
                                                            value={fullName}
                                                            onChange={(e) => {
                                                                setFullName(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                            style={{ marginTop: "10px" }}
                                        >
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="E-Mail Address *"
                                                        className={`${email
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="email"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="E-Mail Address *"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq &&
                                                        (emailaddress == null || emailaddress == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="input-mobile-field d-flex">
                                                        <div
                                                            className="mobile-num"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <FloatingLabel
                                                                label="Mobile *"
                                                                className={`${contactNum
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="tel"
                                                                    autoComplete="off"
                                                                    className="form-control add-user-input"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Mobile *"
                                                                    maxLength={10}
                                                                    value={contactNum}
                                                                    onChange={(e) => setContactNum(e.target.value)}
                                                                // onKeyDown={NumberValidation}
                                                                />
                                                            </FloatingLabel>
                                                            {/* {fieldReq && mobilenum.length !== 10 ? (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {`${alertMessage.message} - 10 Numbers`}&nbsp;
                                                                    <i className="fa fa-exclamation-circle" />
                                                                </small>
                                                            ) : (
                                                                ""
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Transaction Id"
                                                        className={`${transactionId
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Transaction Id *"
                                                            value={transactionId}
                                                            onChange={(e) => {
                                                                setTransactionId(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">

                                                <div className="mb-3">
                                                    <FloatingLabel label='Transaction Date' className={`${transactionDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={transactionDate}
                                                            className={`form-control add-user-input`}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholder="Transaction Date"
                                                            onChange={(date) => handleSelectChange(date)}
                                                            maxDate={new Date()}
                                                        // readOnly={compaintId}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Transaction Amount"
                                                        className={`${transactionAmount
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Transaction Amount"
                                                            value={transactionAmount}
                                                            onChange={(e) => {
                                                                setTransactionAmount(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Reason for refund"
                                                        className={`${reasonForRefund
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Reason for refund"
                                                            value={reasonForRefund}
                                                            onChange={(e) => {
                                                                setResonForRefund(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddRefund;