import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
// import AddUserData from "./AddUserData";
import "../../Styles/AddPage.scss"
import "../../Styles/AddUserDetails.scss"
import AddBranch from "./AddBranch";
import { loadAllBranchData, loadBranchDropdown, loadFilteredUserData } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import BranchTable from "./BranchTable";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";
import { countriesList, indiaStatesList, karnatakaCities } from "../DropdownsData";
import * as XLSX from "xlsx";

function Branch() {

    let tableHeader = [
        { name: "NAME", align: "left", width: "120px" },
        { name: "EMAIL", align: "left", width: "150px" },
        { name: "PHONE", align: "left", width: "150px" },
        { name: "COUNTRY", align: "left", width: "150px" },
        { name: "STATE", align: "left", width: "150px" },
        { name: "CITY", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "150px" },
        { name: "ACTION", align: "left", width: "150px" },
    ];

    const dispatch = useDispatch();
    const { getAllBrachData } = useSelector((state) => state.data);
    const { getBranchDropdown } = useSelector((state) => state.data);
    const { totalCount } = useSelector((state) => state.data);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addusermodelshow, setAddusermodelshow] = useState(false);
    const [openFilterTab, setOpenFilterTab] = useState(false);
    const [PageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [branchId, setBranchId] = useState();
    const [filteredBranchData, setFilteredBranchData] = useState([]);
    const [filterArray, setFilterArray] = useState([]);
    const [filterByBranch, setFilterByBranch] = useState({ value: "", label: "" })
    const [filterByState, setFilterByState] = useState({ value: "", label: "" })
    const [filterByCity, setFilterByCity] = useState({ value: "", label: "" })
    const [searchBranchData, setSearchBranchData] = useState([])

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    useEffect(() => {
        fetchBranchData();
    }, [offset, filterArray]);

    const fetchBranchData = () => {
        dispatch(loadAllBranchData(filterArray, PageSize, offset, setIsLoading, setLoading));
    };

    useEffect(() => {
        dispatch(loadBranchDropdown())
    }, [])

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };
    useEffect(() => {
        setSearchBranchData(
            getAllBrachData?.filter((customer) =>
                Object.values(customer).some(
                    (value) =>
                        typeof value === "string" &&
                        value.toLowerCase().includes(searchValue.toLowerCase())
                )
            )
        );
    }, [getAllBrachData, searchValue]);

    const handleEdit = (bId) => {
        const filteredData = getAllBrachData?.filter((data) => data.branch_id === bId);
        setFilteredBranchData(filteredData);
        setBranchId(bId);
        setAddusermodelshow(true);
    };

    const handleDropdownFilter = (data, type) => {
        let list = [];
        if (type === "branchName") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "BRANCH_NAME");
            }
            list.push({ key: "BRANCH_NAME", value: data.value });
            setFilterByBranch(data);
        } else if (type === "state") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "STATE");
            }
            list.push({ key: "STATE", value: data.value });
            setFilterByState(data);
        } else if (type === "city") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "CITY");
            }
            list.push({ key: "CITY", value: data.value });
            setFilterByCity(data);
        }
        setFilterArray(list);
    };


    const navigate = useNavigate();
    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    const handleClose = () => {
        setAddusermodelshow(!addusermodelshow);
        setBranchId();
    };

    const exportUserDetails = () => {
        const ws = XLSX.utils.json_to_sheet(getAllBrachData.map((data) => ({
            "NAME": data.branch_name,
            "EMAIL": data.email,
            "PHONE": data.phone_number,
            "COUNTRY": data.country,
            "STATE": data.state,
            "CITY": data.city,
            "STATUS": data.status,
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "BranchData");
        XLSX.writeFile(wb, "BranchData.xlsx");
    };

    const clearFilters = () => {
        setFilterArray([]);
        setFilterByBranch({ value: "", label: "" });
        setFilterByState({ value: "", label: "" });
        setFilterByCity({ value: "", label: "" });
    }
    return (
        <div id="main-page">
            {!addusermodelshow && <>
                <div className="top-and-sidebar">
                    <SideNavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar toggleMenu={toggleMenu} menuCollapse={menuCollapse} toggleshift={toggleshift} />
                </div>

                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <div className="user-table">
                                <Row className="search-row">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToSettingPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">Branch({totalCount})</h6>
                                        </div>
                                    </Col>
                                    <Col md={5} className="cat-col">
                                        <div className="input-div">
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                className="form-control setting-input"
                                                id="metricInfo_search_bar"
                                                value={searchValue}
                                                onChange={handleSearchInputChange}
                                            />
                                            {/* <i className="fa fa-search search-icon"></i> */}
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                <div
                                                    className="header-filter-btn"
                                                    onClick={() => setOpenFilterTab(!openFilterTab)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div>
                                                <SbAddBtn
                                                    onClickEffect={() => setAddusermodelshow(!addusermodelshow)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {openFilterTab && (
                                    <div className="page-filter-section">
                                        <div style={{ width: "100%" }}>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="clients-filter-input-boxs filter-box-div">
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={getBranchDropdown}
                                                                value={filterByBranch.value && filterByBranch}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "branchName")
                                                                }
                                                                placeholder="Select Branch"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={indiaStatesList}
                                                                value={filterByState.value && filterByState}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "state")
                                                                }
                                                                placeholder="Select State"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={karnatakaCities}
                                                                value={filterByCity.value && filterByCity}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "city")
                                                                }
                                                                placeholder="Select City"
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                        {/* <div style={{ width: "100%", padding: "0px 5px" }}>
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={optionsStatusList}
                                                                value={status.value && status}
                                                                onChange={(data) =>
                                                                    handleDropdownFilter(data, "status")
                                                                }
                                                                placeholder="Select Status"
                                                                isSearchable={true}
                                                            />
                                                        </div> */}
                                                        <SbCancelBtn
                                                            btnName="Clear"
                                                            onClickEffect={clearFilters}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}
                                <BranchTable
                                    tableHeader={tableHeader}
                                    // getAllBrachData={getAllBrachData}
                                    getAllBrachData={searchValue ? searchBranchData : getAllBrachData}
                                    handleEdit={handleEdit}
                                />
                                <div className="user-table">
                                    {/* PAGINATION START */}
                                    <div className="download-and-pagination">
                                        <SbDownloadBtn
                                            btnName="Download"
                                            onClickEffect={exportUserDetails}
                                            offset={getAllBrachData.length}
                                        />
                                    </div>
                                    {/* PAGINATION END */}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </>}
            {
                addusermodelshow && (
                    <AddBranch
                        // getUserDetails={getUserDetails}
                        addusermodelshow={addusermodelshow}
                        handleClose={handleClose}
                        branchId={branchId}
                        filteredBranchData={filteredBranchData}
                        fetchBranchData={fetchBranchData}
                    />
                )
            }
        </div >
    );
}

export default Branch;
