import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
// import SkeletonTableData from '../Skeleton Loading/SkeletonTableData';

function SubscriptionTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer
                    sx={{ maxHeight: 440 }}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="client-table"
                    >
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                            <>
                                {/* {props.tableHeader.map((header, i) => (
                  <SkeletonClients key={i} />
                ))} */}
                            </>
                        ) : (
                            <>
                                {props.getSubscriptions && props.getSubscriptions.length === 0 ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                colSpan={props.tableHeader.length}
                                            >
                                                --- NO DATA FOUND ---
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {props.getSubscriptions && props.getSubscriptions.map((data, i) => (
                                            <TableRow
                                                align="center"
                                                key={i}
                                                className="client-list-row-data"
                                            >
                                                <TableCell>
                                                    <div className="users-list-name">
                                                        <div>
                                                            <img
                                                                className="table-data-users-imgages company-logo"
                                                                src={`https://accounts.metricinfo.com/static/media/Default_company.1b81cea453b5127f3cae.jpg`}
                                                                alt="client-profile"
                                                            />
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="table-row-head-Text"
                                                            >
                                                                <strong> #{data?.companyid}</strong>
                                                            </div>

                                                            <div className="table-row-sub-text">
                                                                <span>{data?.companyname}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell>
                                                    {data.companyname}
                                                </TableCell> */}
                                                <TableCell>
                                                    {data.contactperson}
                                                </TableCell>
                                                <TableCell>
                                                    {/* {data.emailid} */}
                                                    <span className="color-green">{data?.phonenumber}</span>{" "}
                                                    <br />
                                                    <span>{data?.emailid}</span>
                                                </TableCell>
                                                {/* <TableCell>
                                                    {data.phonenumber}
                                                </TableCell> */}
                                                <TableCell>
                                                    {data.firstsubdate ?? "NA"}
                                                </TableCell>
                                                <TableCell>
                                                    {data.startdate}
                                                </TableCell>
                                                <TableCell>
                                                    {data.enddate}
                                                </TableCell>
                                                <TableCell>
                                                    {data.leadgen}
                                                </TableCell>
                                                <TableCell>
                                                    {data.bde}
                                                </TableCell>
                                                <TableCell>
                                                    {data.numberofusers}
                                                </TableCell>
                                                <TableCell>
                                                    {data.subscriptiondate}
                                                </TableCell>
                                                <TableCell>
                                                    {data.subtype}
                                                </TableCell>
                                                <TableCell>
                                                    {data?.status === "Active" ? (
                                                        <span className="status-badge complete-status">
                                                            Active
                                                        </span>
                                                    ) : (
                                                        <span className="status-badge cancel-status">
                                                            InActive
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <span className="user-action-td" onClick={() => { props.handleEdit(data.companyid) }}>
                                                        <Link className="user-edit-link">
                                                            <i className="fa fa-edit"></i> Edit
                                                        </Link>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default SubscriptionTable;
