import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useRef, useState } from 'react';
// import SkeletonTableData from '../Skeleton Loading/SkeletonTableData';

function DepositeTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer
                    sx={{ maxHeight: 440 }}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="client-table"
                    >
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                            <>
                                {/* {props.tableHeader.map((header, i) => (
                  <SkeletonClients key={i} />
                ))} */}
                            </>
                        ) : (
                            <>
                                {props.depositeData && props.depositeData.length === 0 ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                colSpan={props.tableHeader.length}
                                            >
                                                --- NO DATA FOUND ---
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {props.depositeData.map((data, i) => (
                                            <TableRow
                                                align="center"
                                                key={i}
                                                className="client-list-row-data"
                                            >
                                                <TableCell>
                                                    {data.depositId}
                                                </TableCell>
                                                <TableCell>
                                                    {data.customerId}
                                                </TableCell>
                                                <TableCell>
                                                    {data.amount}
                                                </TableCell>
                                                <TableCell>
                                                    {data.date}
                                                </TableCell>
                                                <TableCell>
                                                    {data.method}
                                                </TableCell>
                                                <TableCell>
                                                    {data.status}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default DepositeTable;
