import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addUserData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const bankNamesList = [
    { value: '1', label: 'State Bank of India' },
    { value: '2', label: 'ICICI Bank' },
    { value: '3', label: 'HDFC Bank' },
    { value: '4', label: 'Axis Bank' },
    { value: '5', label: 'Bank of Baroda' },
    { value: '6', label: 'Punjab National Bank' },
    { value: '7', label: 'Canara Bank' },
    { value: '8', label: 'Bank of India' },
    { value: '9', label: 'IndusInd Bank' },
    { value: '10', label: 'Kotak Mahindra Bank' },
];


const AddOnline = (props) => {

    let dispatch = useDispatch();
    const [amount, setAmount] = useState("");
    const [bankName, setBankName] = useState({ value: "", label: "" });
    const [accountNumber, setAccountNumber] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [branch, setBranch] = useState("");
    const [depositorsName, setDepositorsName] = useState("");

    const handleSelectChange = (date) => {
        setSelectedDate(date);
    };

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div show={props.addCashModel} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Online Transaction</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>Online Transaction Details</h4>
                            <p>Please enter online transaction infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                        // onSubmit={handleUserData}
                        >
                            <div className="row">

                                <div className="col-lg-12 col">
                                    <div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Amount"
                                                        className={`${amount
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Amount"
                                                            value={amount}
                                                        // onChange={(e) => {
                                                        //     setFullName(e.target.value);
                                                        // }}
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (fullname == null || fullname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Bank Name"
                                                        className={`${!bankName
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={bankNamesList}
                                                            placeholder="Bank Name"
                                                            value={bankName.value && bankName}
                                                            onInputChange={bankNamesList.label}
                                                            onChange={(data) =>
                                                                setBankName(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq && (usercode == null || usercode == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                        // style={{ marginTop: "10px" }}
                                        >
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Account Number"
                                                        className={`${accountNumber
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Account Number"
                                                            value={accountNumber}
                                                            onChange={(e) => setAccountNumber(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {/* {fieldReq &&
                                                        (emailaddress == null || emailaddress == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel label='Date' className={`${selectedDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            className={`form-control add-user-input`}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholder="Date"
                                                            onChange={(date) => handleSelectChange(date)}
                                                            maxDate={new Date()}
                                                        // readOnly={compaintId}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Branch"
                                                        className={`${branch
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Branch"
                                                            value={branch}
                                                            onChange={(e) => setBranch(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Depositors Name"
                                                        className={`${depositorsName
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Depositors Name"
                                                            value={depositorsName}
                                                            onChange={(e) => setDepositorsName(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddOnline;