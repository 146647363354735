import React from "react";

function SbCancelBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-sb-cancel"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp}
            >
                {props.btnName}
            </button>
        </div>
    );
}

export default SbCancelBtn;