import { Link } from "react-router-dom";
import SalesBee from "../../Images/metricinfo-payment-logo.svg";
import profileimg from "../../Images/profile-1.png";
import { useEffect, useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { addCustomerData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { countriesList, indiaStatesList, karnatakaCities } from "../DropdownsData";

const AddCustomer = (props) => {

    const filteredCustomerData = props.filteredCustomerData;
    const customId = props.customId;

    let dispatch = useDispatch();
    const [localImage, setLocalImage] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [customerId, setCustomerId] = useState();
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState({ value: "", label: "" });
    const [state, setState] = useState({ value: "", label: "" });
    const [city, setCity] = useState({ value: "", label: "" });
    const [postalCode, setPostalCode] = useState();
    const [address, setAddress] = useState();
    const [contactNum, setContactNum] = useState("")
    const [businessType, setBusinessType] = useState("");
    const [status, setStatus] = useState("Active");
    const [alertMessage, setAlertMessage] = useState({
        alertType: "",
        message: "",
    });
    const [fieldReq, setFieldReq] = useState(false);

    useEffect(() => {
        if (customId && filteredCustomerData && filteredCustomerData.length > 0) {
            setCompanyName(filteredCustomerData[0].companyname)
            setContactPerson(filteredCustomerData[0].contactperson)
            setEmail(filteredCustomerData[0].contactemail)
            setContactNum(filteredCustomerData[0].contactphone)
            setPostalCode(filteredCustomerData[0].postalcode)
            setAddress(filteredCustomerData[0].address)
            setBusinessType(filteredCustomerData[0].businesstype)
        }
    }, [])

    useEffect(() => {
        if (customId && filteredCustomerData && filteredCustomerData.length > 0) {
            countriesList.map((item) => {
                if (item.label === filteredCustomerData[0].country)
                    setCountry(item)
            })
            indiaStatesList.map((item) => {
                if (item.value === filteredCustomerData[0].state)
                    setState(item)
            })
            karnatakaCities.map((item) => {
                if (item.value === filteredCustomerData[0].city)
                    setCity(item)
            })
        }
    }, [customId, filteredCustomerData])

    const handleCustomerData = (e) => {
        e.preventDefault();
        if (
            !companyname ||
            !contactPerson ||
            !email ||
            !country.value ||
            !state.value ||
            !city.value ||
            !postalCode ||
            !address ||
            !contactNum ||
            contactNum.length !== 10
        ) {
            setAlertMessage({
                alertType: "fails",
                message: "Required...!",
            });
            setFieldReq(true);
            return;
        }
        // Get the current date and time in the desired 24-hour format
        const formattedDateTime = moment().format('DD-MM-YYYY HH:mm');

        // Convert the formatted date and time to an integer by removing special characters
        const dateTimeInt = parseInt(formattedDateTime.replace(/[-\s:]/g, ''));
        let methodType = customId ? "PUT" : "POST";
        const data = {
            "CustomerID": customId ? customId : parseInt(dateTimeInt),
            "CompanyName": companyname,
            "ContactPerson": contactPerson,
            "ContactEmail": email,
            "ContactPhone": contactNum,
            "BusinessType": businessType,
            "Country": country.label,
            "State": state.label,
            "City": city.label,
            "PostalCode": postalCode,
            "Address": address,
            "Status": "Active",
        }
        let postData = {
            method: methodType,
            mode: 'cors',
            // headers: HeaderAuthentication.postTravelizeAuth,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        console.log(data, 'data')
        dispatch(addCustomerData(postData, closeModel, customId,props.fetchCustomer))
    }

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div show={props.addusermodelshow} onHide={props.handleClose}>
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className="machines-logo-div">
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div
                        className="add-user-details-menus"
                    // onClick={toggleMachineDetails}
                    >
                        <div
                            className="add-user-details-menu-numbers active"
                        //   ${
                        // activeMenu === 1 ? "active" : "inactive"
                        //   }`}
                        >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Customer Details</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel}>
                            <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>Customer Details</h4>
                            <p>Please enter customer infomation and proceed to the next step.</p>
                        </div>
                    </div>

                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form
                            onSubmit={handleCustomerData}
                        >
                            <div className="row">
                                <div className="col-lg-3 col">
                                    <div className="add-user-main-logo-image">
                                        <img
                                            className="add-user-images"
                                            src={localImage ? localImage : profileimg}
                                            alt="Profile Images"
                                        />
                                        <div>
                                            <button
                                                type="button"
                                                className="btn upload-logo"
                                            // onClick={uploadClick}
                                            >
                                                {/* <img
                                                    src="../images/uploadImage/camera.svg"
                                                    alt="camera"
                                                /> */}
                                                <i className="bi bi-camera-fill" />
                                            </button>
                                            <input
                                                id="getFile"
                                                type="file"
                                                name="filetobase64"
                                                accept="image/*"
                                                className="btn-primary upload"
                                                // ref={hiddenFileInput}
                                                // onChange={handleChange}
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col">
                                    <div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Company Name *"
                                                        className={`${companyname
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Company Name *"
                                                            value={companyname}
                                                            onChange={(e) => {
                                                                setCompanyName(e.target.value);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {fieldReq && (companyname == null || companyname == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Contact Person *"
                                                        className={`${contactPerson
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Contact Person *"
                                                            value={contactPerson}
                                                            onChange={(e) => setContactPerson(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {fieldReq &&
                                                        (contactPerson == null || contactPerson == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                        >
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="input-mobile-field d-flex">
                                                        <div
                                                            className="mobile-num"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <FloatingLabel
                                                                label="Email Id *"
                                                                className={`${email
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="email"
                                                                    autoComplete="off"
                                                                    className="form-control add-user-input"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Email Id *"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                // onKeyDown={NumberValidation}
                                                                />
                                                            </FloatingLabel>
                                                            {fieldReq &&
                                                                (email == null || email == "") ? (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {alertMessage.message}&nbsp;
                                                                    <i className="fa fa-exclamation-circle" />
                                                                </small>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="input-mobile-field d-flex">
                                                        <div
                                                            className="mobile-num"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <FloatingLabel
                                                                label="Contact Number *"
                                                                className={`${contactNum
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="tel"
                                                                    autoComplete="off"
                                                                    className="form-control add-user-input"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Contact Number *"
                                                                    maxLength={10}
                                                                    value={contactNum}
                                                                    onChange={(e) => setContactNum(e.target.value)}
                                                                // onKeyDown={NumberValidation}
                                                                />
                                                            </FloatingLabel>
                                                            {fieldReq && contactNum.length !== 10 ? (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    {`${alertMessage.message} - 10 Numbers`}&nbsp;
                                                                    <i className="fa fa-exclamation-circle" />
                                                                </small>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Business Type *"
                                                        className={`${businessType
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Business Type *"
                                                            value={businessType}
                                                            onChange={(e) => setBusinessType(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                    {fieldReq &&
                                                        (businessType == null || businessType == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select Country"
                                                        className={`${!country
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={countriesList}
                                                            placeholder="Select Country"
                                                            value={country.value && country}
                                                            onInputChange={countriesList.label}
                                                            onChange={(data) =>
                                                                setCountry(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {fieldReq && (country.value == null || country.value == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="row add-user-image-container-div"
                                        >

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select State"
                                                        className={`${!state
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={indiaStatesList}
                                                            placeholder="Select State"
                                                            value={state.value && state}
                                                            onInputChange={indiaStatesList.label}
                                                            onChange={(data) =>
                                                                setState(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {fieldReq &&
                                                        (state.value == null || state.value == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select City"
                                                        className={`${!city
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={karnatakaCities}
                                                            placeholder="Select City"
                                                            value={city.value && city}
                                                            onInputChange={karnatakaCities.label}
                                                            onChange={(data) =>
                                                                setCity(data)
                                                            }
                                                            isSearchable={true}
                                                        // required
                                                        />
                                                    </FloatingLabel>
                                                    {fieldReq &&
                                                        (city.value == null || city.value == "") ? (
                                                        <small
                                                            id={`Error`}
                                                            className="form-text text-muted "
                                                        >
                                                            {alertMessage.message}&nbsp;
                                                            <i className="fa fa-exclamation-circle" />
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row add-user-image-container-div"
                                        >

                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Postal Code *"
                                                        className={`${postalCode
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control add-user-input"
                                                            id="exampleFormControlInput1"
                                                            placeholder="Postal Code *"
                                                            value={postalCode}
                                                            onChange={(e) => setPostalCode(e.target.value)}
                                                            required
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <div className="mb-3">
                                                        <FloatingLabel label='Address' className={`${address ? "float-input-visible" : "float-hidden float-input"}`}>
                                                            <textarea
                                                                rows="2"
                                                                name="issue"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Address"
                                                                value={address}
                                                                onChange={
                                                                    (e) => { setAddress(e.target.value) }
                                                                }
                                                            />
                                                        </FloatingLabel>
                                                        {fieldReq &&
                                                            (address == null || address == "") ? (
                                                            <small
                                                                id={`Error`}
                                                                className="form-text text-muted "
                                                            >
                                                                {alertMessage.message}&nbsp;
                                                                <i className="fa fa-exclamation-circle" />
                                                            </small>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-page-save-cancel">
                                <SBSaveUpdateBtn btnName="Save" />
                                <SbCancelBtn
                                    btnName="Cancel"
                                    onClickEffect={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCustomer;