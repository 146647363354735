import React, { useEffect, useState } from "react";
import "../../Styles/Users.scss";
import "../../Styles/CommonStyle.scss";
import SideNavbar from "../SideNavbar";
import Topbar from "../Topbar";
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/AddPage.scss"
import "../../Styles/AddUserDetails.scss"
import "../../Styles/Dashboard.scss"
import { useDispatch, useSelector } from "react-redux";
import { loadUserCount } from "../../Redux/action";

function Dashboard() {

    const dispatch = useDispatch();
    const { getUserCount } = useSelector((state) => state.data);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(false);
    const [addusermodelshow, setAddusermodelshow] = useState(false);

    useEffect(() => {
        dispatch(loadUserCount());
    }, [])

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    // Page navigation
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    const handleClose = () => {
        setAddusermodelshow(!addusermodelshow);
    };

    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <SideNavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}
            <div className="page-wrapper">
                {/* MAIN BODY START */}
                <div className={`main-body ${toggleshift.style}`}>
                    {/* PAGE CONTENT START */}

                    <div className="page-content-dash">
                        {/* FIRST ROW START */}
                        <div className="sb-card-row">
                            <div className="card card-dashboard">
                                <div className="card-body first-card">
                                    <div className="row card-row">
                                        <div className="col-md-3 card-left-img">
                                            <span class="material-symbols-outlined">
                                                paid
                                            </span>
                                        </div>
                                        <div className="col-md-9 card-right-data">
                                            <div className="verticle-line"></div>
                                            <Link className="act-body" to="">
                                                <p className="card-head">
                                                    Total Deposites
                                                </p>
                                                <p>
                                                    <b className="act-num">
                                                        1000
                                                    </b>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-dashboard">
                                <div className="card-body first-card">
                                    <div className="row card-row">
                                        <div className="col-md-3 card-left-img">
                                            <span class="material-symbols-outlined">
                                                send_money
                                            </span>
                                        </div>
                                        <div className="col-md-9 card-right-data">
                                            <div className="verticle-line"></div>
                                            <Link className="act-body" to="">
                                                <p className="card-head">
                                                    Total Refund
                                                </p>
                                                <p>
                                                    <b className="act-num">
                                                        1000
                                                    </b>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-dashboard">
                                <div className="card-body first-card">
                                    <div className="row card-row">
                                        <div className="col-md-3 card-left-img">
                                            <span class="material-symbols-outlined">
                                                autorenew
                                            </span>
                                        </div>
                                        <div className="col-md-9 card-right-data">
                                            <div className="verticle-line"></div>
                                            <Link className="act-body" to="">
                                                <p className="card-head">
                                                    Total Recurring
                                                </p>
                                                <p>
                                                    <b className="act-num">
                                                        1000
                                                    </b>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-dashboard">
                                <div className="card-body first-card">
                                    <div className="row card-row">
                                        <div className="col-md-3 card-left-img">
                                            <span class="material-symbols-outlined">
                                                group
                                            </span>
                                        </div>
                                        <div className="col-md-9 card-right-data">
                                            <div className="verticle-line"></div>
                                            <Link className="act-body" to="">
                                                <p className="card-head">
                                                    Total Users
                                                </p>
                                                <p>
                                                    <b className="act-num">
                                                        {getUserCount.length > 0 && getUserCount[0]["count(*)"]}
                                                    </b>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-dashboard">
                                <div className="card-body first-card">
                                    <div className="row card-row">
                                        <div className="col-md-3 card-left-img">
                                            <span class="material-symbols-outlined">
                                                group
                                            </span>
                                        </div>
                                        <div className="col-md-9 card-right-data">
                                            <div className="verticle-line"></div>
                                            <Link className="act-body" to="">
                                                <p className="card-head">
                                                    Total Customers
                                                </p>
                                                <p>
                                                    <b className="act-num">
                                                        1000
                                                    </b>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
