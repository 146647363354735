import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';
import { SbDownloadBtn } from '../SbButtons/SbAddBtn';

function UsersTable(props) {
    const lastItemSerialNumber = props.getUsers.length > 0
        ? (props.getUsers.length)
        : 0;
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer
                    sx={{ maxHeight: 440 }}
                    onScroll={(e) =>
                        handleScroll(
                            e,
                            props.fetchData,
                            props.isLoading,
                            props.setLoading,
                            props.setOffset,
                            props.offset,
                            props.PageSize,
                            props.hasMoreData
                        )
                    }
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="client-table"
                    >
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    <TableCell key={i} align={name.align} className={`thead-cell-products`} style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.getUsers.length === 0 && !props.loading ? (
                                <TableRow>
                                    <TableCell align="center" colSpan={props.tableHeader.length}>
                                        --- NO DATA FOUND ---
                                    </TableCell>
                                </TableRow>
                            ) : (
                                props.getUsers.map((data, i) => (
                                    <TableRow align="center" key={i} className="client-list-row-data">
                                        <TableCell>{i + 1}</TableCell>
                                        {/* <TableCell>{data.rn}</TableCell> */}
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell>{data.phonenumber}</TableCell>
                                        <TableCell>{data.designation}</TableCell>
                                        <TableCell>{data.reportingto}</TableCell>
                                        <TableCell>
                                            {data?.status === "Active" ? (
                                                <span className="status-badge complete-status">Active</span>
                                            ) : (
                                                <span className="status-badge cancel-status">InActive</span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <span className="user-action-td" onClick={() => props.handleEdit(data.id)}>
                                                <Link className="user-edit-link">
                                                    <i className="fa fa-edit"></i> Edit
                                                </Link>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {props.loading && <OnScrollLoading />}
                <div className="user-table">
                    {/* PAGINATION START */}
                    <div className="download-and-pagination">
                        <SbDownloadBtn
                            btnName="Download"
                            onClickEffect={props.exportUserDetails}
                            offset={lastItemSerialNumber}
                        />
                    </div>
                    {/* PAGINATION END */}
                </div>
            </Paper>
        </div>
    );
}

export default UsersTable;


