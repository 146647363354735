import React from "react";

function SbAddBtn(props) {
  return (
    <div className="dropdown add-single-bulk">
      <button
        className="btn btn-add-dropdown"
        id="adduser-single-blulk"
        onClick={props.onClickEffect && props.onClickEffect}
        disabled={props.disableProp}
      >
        <div className="btn-inline-item d-flex align-items-center">
          <i className="fa fa-plus-circle"> </i>
          &nbsp;&nbsp;
          <h6 style={{ fontSize: "13px", fontWeight: "500" }}>Add</h6>
        </div>
      </button>
    </div>
  );
}
export default SbAddBtn;

export function SBSaveUpdateBtn(props) {
  return (
    <div className="dropdown add-single-bulk">
      <button
        type={!props.onClickEffect ? "submit" : "button"}
        className="btn btn-add-save-update"
        id="adduser-single-blulk"
        onClick={props.onClickEffect && props.onClickEffect}
        disabled={props.disableProp ?? false}
      >
        {props.btnName}
      </button>
    </div>
  );
}

export function SbDownloadBtn(props) {
  return (
    <div className="dropdown add-single-bulk">
      <button
        className="btn btn-add-dropdown"
        id="adduser-single-blulk"
        onClick={props.onClickEffect && props.onClickEffect}
      >
        <div className="btn-inline-item d-flex align-items-center">
          <i className="fa fa-download" />
          &nbsp;&nbsp;
          <h6 style={{ fontSize: "13px", fontWeight: "500" }}>
            {props.btnName ? props.btnName : "Download"}
          </h6> &nbsp;&nbsp;
          {props.offset !== undefined && (
            <h6 style={{ fontSize: "13px", fontWeight: "500" }}>
              ({props.offset})
            </h6>
          )}
        </div>
      </button>
    </div>
  );
}