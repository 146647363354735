import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Time (in milliseconds) after which the user should be logged out due to inactivity
const TIMEOUT_DURATION = 10 * 60 * 1000; // 10 minutes

const SessionTimeout = () => {
    const [logoutTimer, setLogoutTimer] = useState(null);

    useEffect(() => {
        const handleActivity = () => {
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
            setLogoutTimer(setTimeout(() => {
                sessionStorage.clear();
                window.location.href = "/";
            }, TIMEOUT_DURATION));
        };

        // Set the initial timeout
        const initialTimer = setTimeout(() => {
            sessionStorage.clear();
            window.location.href = "/";
        }, TIMEOUT_DURATION);

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            clearTimeout(initialTimer);
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
        };
    }, [logoutTimer]);

    return null;
};

export default SessionTimeout;
