import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './Components/Signup';
import Signin from './Components/Signin';
import Users from './Components/Users/Users';
import Customer from './Components/Customer/Customer';
import Branch from './Components/Branch/Branch';
import Cash from './Components/Cash/Cash';
import Check from './Components/Check/Check';
import Online from './Components/Online/Online';
import Deposite from './Components/Deposite/Deposite';
import Refund from './Components/Refund/Refund';
import Recurring from './Components/Recurring/Recurring';
import Reports from './Components/Reports/Reports';
import Subscription from './Components/Subscription/Subscription';
import Maintenance from './Components/Maintenance';
import Dashboard from './Components/Dashboard/Dashboard';
import SessionTimeout from './Components/SessionTimeout';

function App() {
  const status = sessionStorage.getItem("status");

  return (
    <div className="App">
      {status ? (
        <Router>
          <SessionTimeout />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/customers" element={<Customer />} />
            <Route path="/branch" element={<Branch />} />
            <Route path="/cash" element={<Cash />} />
            <Route path="/check" element={<Check />} />
            <Route path="/online" element={<Online />} />
            <Route path="/deposite" element={<Deposite />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/recurring" element={<Recurring />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/subscriptions" element={<Subscription />} />
            <Route path="/maintenance" element={<Maintenance />} />
          </Routes>
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/" element={<Signin />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
